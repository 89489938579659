import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import TableComponent from 'components/table/Table';
import { productsColumns } from '../variables/table';
import ChevronDown from 'assets/icons/chevrons/ChevronDown';
import usePage from 'hooks/usePage';
import useListRefetch from 'hooks/useListRefetch';
import { useSellerProducts } from '../api/getSellerProducts';

const ListSellerProducts = () => {
  const { page } = usePage();
  const { sellerId } = useParams();

  const { data: products, refetch: refetchSellerProducts } = useSellerProducts({
    query: {
      page: Number(page ?? '1'),
      limit: 8,
      sellerId,
    },
  });

  useListRefetch({
    refetch: refetchSellerProducts,
    page: page,
    currentPage: products?.meta?.currentPage,
  });

  const breadItems = [
    {
      name: <FormattedMessage id="users" />,
    },
    {
      name: <FormattedMessage id="partners" />,
    },
    {
      name: <FormattedMessage id="vendors" />,
      link: `/users/partners/sellers`,
    },
    {
      name: products?.store?.name,
    },
    {
      name: <FormattedMessage id="products" />,
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breadItems} />
        <HStack>
          <Menu>
            <MenuButton
              //  color="primary.500"
              minW="150px"
              borderRadius="31px"
              fontSize="14px"
              py="5px"
              bg="white"
              fontWeight="500"
              h="31px"
              px="20px"
              justifyContent="space-between"
              as={Button}
              variant="whiteFill"
              rightIcon={
                <Icon fill="none" viewBox="0 0 12 8" stroke="gray.500">
                  {' '}
                  <ChevronDown />{' '}
                </Icon>
              }
            >
              <Text
                _firstLetter={{ textTransform: 'capitalize' }}
                textAlign="start"
              >
                <FormattedMessage id={'seller'} />
              </Text>
            </MenuButton>

            <MenuList borderRadius="xl">
              <Link to="/users/partners/organizers">
                <MenuItem>
                  <FormattedMessage id="eventOrganizer" />
                </MenuItem>
              </Link>
              <Link to="/users/partners/sellers">
                <MenuItem>
                  <FormattedMessage id="seller" />
                </MenuItem>
              </Link>
            </MenuList>

            {/* <Link to="/events/city/new">
                  <Button 
                   < color="primary.500"
                    borderRadius="31px"
                    fontSize="14px" 
                    py="5px" 
                    bg="white"
                    fontWeight="500"
                    h="31px">
                    iconSpacing="5px"
                    leftIcon={<Icon viewBox="0 0 17 15" width="17" height="15" fill="primary.500" >
                    <AddCityIcon />
                  </Icon>}
                  >
                    <Text               
                      _firstLetter={
                        {
                          textTransform: "capitalize"
                        }
                      }
                      fontSize="14px"
                    >
                      <FormattedMessage  id="newCity" />
                    </Text>
                  </Button>
              </Link> */}
          </Menu>
        </HStack>
      </HStack>
      <TableComponent<ProductListEntity>
        name="events"
        // name="users"
        // selectRow={navigateOrderDetails}
        data={products?.products ?? []}
        tableColumns={productsColumns}
        pageIndex={Number(page ?? '1')}
        pageCount={products?.meta?.totalPages ?? 1}
        paginated={true}
        hideSelection={true}
        //
        // searching={setSearch}
        // detailsIcon={true}
      />
    </Stack>
  );
};

ListSellerProducts.propTypes = {};

export default ListSellerProducts;
