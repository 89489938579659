// id : number ,
// partnerName: string,
// storeName: string,

import Cell from 'components/table/components/cell';
import OrganizerNameCell from '../components/table/OrganizerNameCell';
import DateCell from 'components/table/components/date';
import UserStatus from 'features/users/components/table/UserStatus';
import OrganizerMenuCell from '../components/table/SellerMenuCell';
// import PartnerMenuCell from '../components/table/PartnerMenuCell';

// orderNumber: number
export const eventOrganizersColumn = [
  {
    Header: 'user',
    accessor: 'organizerName',
    Cell: OrganizerNameCell,
  },
  {
    Header: 'subcriptionDate',
    accessor: 'subscriptionDate',
    Cell: DateCell,
  },
  {
    Header: 'phoneNumber',
    accessor: 'phoneNumber',
    Cell: Cell,
  },
  {
    Header: 'email',
    accessor: 'email',
    Cell: Cell,
  },
  {
    Header: 'address',
    accessor: 'address',
    Cell: Cell,
  },
  {
    Header: 'zip',
    accessor: 'zip',
    Cell: Cell,
  },
  {
    Header: 'events',
    accessor: 'numberOfEvents',
    Cell: Cell,
  },
  {
    Header: 'status',
    accessor: 'status',
    Cell: UserStatus,
  },
  {
    Header: '',
    accessor: 'consultData',
    Cell: OrganizerMenuCell,
  },
];
