import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractSellerProducts } from '../utils/extactData';

type PartnerResponseProps = {
  meta: Meta;
  products: ProductResponseListEntity[];
  store: Store;
};
type PartnerProps = { meta: Meta; products: ProductListEntity[]; store: Store };

type getPartnersQueryType = {
  page?: number;
  limit?: number;
  sellerId?: string;
};

export const getSellerProducts = async ({
  page = 1,
  limit = 5,
  sellerId,
}: getPartnersQueryType): Promise<PartnerProps | null> => {
  if (!sellerId) {
    return null;
  }
  const sellerProductsResponse = (await axios.get(
    `/api/stores/${sellerId}/products-admin`,
    {
      params: {
        page,
        limit,
      },
    },
  )) as PartnerResponseProps;
  const products = extractSellerProducts(
    sellerProductsResponse?.products as ProductResponseListEntity[],
  );

  return {
    meta: sellerProductsResponse?.meta,
    store: sellerProductsResponse?.store,
    products,
  };
};

type QueryFnType = typeof getSellerProducts;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: getPartnersQueryType;
};

export const useSellerProducts = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`partners/sellerProduct/${query?.sellerId}`],
    queryFn: () => getSellerProducts(query),
    ...config,
  });
};
