import { safingEditorOutput } from 'utils/editor';

import {
  ParagraphOutput,
  HeaderOutput,
  ListOutput,
} from 'editorjs-react-renderer';
import { Box } from '@chakra-ui/react';

const BaseEditorOutput = ({ data }: any) => {
  const blocks = safingEditorOutput(data)?.blocks ?? [];
  return (
    <Box>
      {blocks?.map((block: any) => {
        let className = '';

        if (block?.tunes?.anyTuneName?.alignment) {
          className = `ce-tune-alignment--${block?.tunes?.anyTuneName?.alignment}`;
        }

        if (block.type === 'paragraph') {
          return (
            <Box className={className}>
              <ParagraphOutput data={block?.data ?? {}} />
            </Box>
          );
        } else if (block.type === 'header') {
          return (
            <Box className={`${className}`}>
              <HeaderOutput data={block?.data ?? {}} />
            </Box>
          );
        } else if (block.type === 'list') {
          return (
            <Box className={`${className} cdx-list`}>
              {' '}
              <ListOutput data={block?.data ?? {}} />
            </Box>
          );
        }
      })}
    </Box>
    // <EditorOutput
    //   key={`editor-show-${data?.id ?? uuid()}`}
    //   data={safingEditorOutput(data)}
    // />
  );
};

export default BaseEditorOutput;
