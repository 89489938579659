import { HStack, Stack } from '@chakra-ui/react';
import TableComponent from 'components/table/Table';
// import ExpendedBlogRow from '../components/table/ExpendedBlogRow';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import { useIntl } from 'react-intl';
import { useSellerOffers } from '../api/getSellerOffers';
import { useParams } from 'react-router-dom';
import useListRefetch from 'hooks/useListRefetch';
import usePage from 'hooks/usePage';
import { offersColumns } from '../variables/table';

const SellerOffersList = () => {
  const { page } = usePage();
  const { sellerId } = useParams();

  const { data: offers, refetch: refetchSellerOffers } = useSellerOffers({
    query: {
      page: Number(page ?? '1'),
      limit: 8,
      sellerId,
    },
  });

  useListRefetch({
    refetch: refetchSellerOffers,
    page: page,
    currentPage: offers?.meta?.currentPage,
  });
  const intl = useIntl();
  const breacrumItems = [
    {
      name: intl.formatMessage({ id: 'services' }),
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breacrumItems} />
        <HStack></HStack>
      </HStack>

      <TableComponent<OfferListEntity>
        name="offers"
        // name="users"
        // selectRow={navigateOrderDetails}
        data={offers?.offers ?? []}
        tableColumns={offersColumns}
        pageIndex={1}
        pageCount={1}
        paginated={true}
        // ExpendedRow={ExpendedBlogRow}
        //
        // searching={setSearch}
        // detailsIcon={true}
      />
    </Stack>
  );
};

export default SellerOffersList;
