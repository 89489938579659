// id : number ,
// clientName: string,
// storeName: string,

import Cell from 'components/table/components/cell';
import ClientNameCell from '../components/table/ClientNameCell';
import Date from 'components/table/components/date';
import ClientMenuCell from '../components/table/ClientMenuCell';
import UserStatus from 'features/users/components/table/UserStatus';
import EventNameCell from 'features/events/components/table/EventNameCell';
import ClientPaymentPriceCell from '../components/table/EventPaymentPrice';
import DateCell from 'components/table/components/date';
import EventTicketIdCell from '../components/table/EventTicketIdCell';
import EventReservationReceiptCell from '../components/table/EventReservationReceiptCell';
import CouponIdCell from 'features/events/components/table/coupon/CouponIdCell';
import OrderIdCell from 'features/events/components/table/coupon/OrderIdCell';
import FriendInvitationIdCell from '../components/table/FriendInvitationIdCell';
import ClientOrderMenuCell from '../components/table/ClientOrderMenuCell';
import ProductIdCell from '../components/table/ProductIdCell';
import ProductPhotoCell from 'features/seller/components/table/ProductPhotoCell';
import ProductNameCell from 'features/services/components/table/product/ProductNameCell';
import ProductDescriptionCell from 'features/services/components/table/product/ProductDescriptionCell';
import ProductPriceCell from 'features/services/components/table/product/ProductPriceCell';

// orderNumber: number
export const clientsColumns = [
  {
    Header: 'client',
    accessor: 'fullName',
    Cell: ClientNameCell,
  },
  {
    Header: 'subcriptionDate',
    accessor: 'subscriptionDate',
    Cell: Date,
  },
  {
    Header: 'phoneNumber',
    accessor: 'phoneNumber',
    Cell: Cell,
  },
  {
    Header: 'score',
    accessor: 'score',
    Cell: Cell,
  },
  {
    Header: 'tickets',
    accessor: 'tickets',
    Cell: Cell,
  },
  {
    Header: 'status',
    accessor: 'status',
    Cell: UserStatus,
  },
  {
    Header: '',
    accessor: 'consultData',
    Cell: ClientMenuCell,
  },
];

// orderNumber: number
export const eventReservationColumns = [
  {
    Header: 'ticketId',
    accessor: 'id',
    Cell: EventTicketIdCell,
  },
  {
    Header: 'event',
    accessor: 'title',
    Cell: EventNameCell,
  },
  {
    Header: 'price',
    accessor: 'price',
    Cell: ClientPaymentPriceCell,
  },
  {
    Header: 'paymentMethod',
    accessor: 'paymentMethod',
    Cell: Cell,
  },
  {
    Header: 'reservationDate',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'receipt',
    accessor: 'receipt',
    Cell: EventReservationReceiptCell,
  },
];

export const clientCouponsColumns = [
  {
    Header: 'couponId',
    accessor: 'id',
    Cell: CouponIdCell,
  },
  {
    Header: 'event',
    accessor: 'eventTitle',
    Cell: EventNameCell,
  },
  {
    Header: 'couponCode',
    accessor: 'promoCode',
    Cell: Cell,
  },
  {
    Header: 'dateOfUse',
    accessor: 'useDate',
    Cell: Date,
  },
];

export const clientOrdersColumns = [
  {
    Header: 'orderId',
    accessor: 'id',
    Cell: OrderIdCell,
  },
  {
    Header: 'vendor',
    accessor: 'storeName',
    Cell: OrderIdCell,
  },
  {
    Header: 'totalPoints',
    accessor: 'points',
    Cell: Cell,
  },
  {
    Header: 'orderDate',
    accessor: 'createdAt',
    Cell: Date,
  },
  {
    Header: '',
    accessor: 'consultData',
    Cell: ClientOrderMenuCell,
  },
];

export const clientInvitedFriendsColumns = [
  {
    Header: 'operationId',
    accessor: 'id',
    Cell: FriendInvitationIdCell,
  },
  {
    Header: 'userName',
    accessor: 'userName',
    Cell: Cell,
  },
  {
    Header: 'pointEarned',
    accessor: 'earnedPoints',
    Cell: Cell,
  },
  {
    Header: 'inscriptionDate',
    accessor: 'createdAt',
    Cell: Cell,
  },
];

export const clientOrderProductsColumns = [
  {
    Header: 'productId',
    accessor: 'productId',
    Cell: ProductIdCell,
  },
  {
    Header: 'picture',
    accessor: 'productPicture',
    Cell: ProductPhotoCell,
  },
  {
    Header: 'productName',
    accessor: 'productName',
    Cell: ProductNameCell,
  },
  {
    Header: 'productDescription',
    accessor: 'productDescription',
    Cell: ProductDescriptionCell,
  },
  {
    Header: 'price',
    accessor: 'price',
    Cell: ProductPriceCell,
  },
  {
    Header: 'items',
    accessor: 'quantity',
    Cell: Cell,
  },
];
