import { Stack } from '@chakra-ui/react';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import React from 'react';
import { useIntl } from 'react-intl';
import NotificationBox from '../components/NotificationBox';
import { useInfiniteQuery } from '@tanstack/react-query';
import { axios } from 'lib/axios';

const NotificationPage = () => {
  const intl = useIntl();

  const notificationsBreadcrumbs = [
    {
      name: intl.formatMessage({ id: 'notifications' }),
    },
  ];

  const { data: notificationPages } = useInfiniteQuery<
    PaginatedResponse<'notification', NotificationAdmin>
  >({
    queryKey: ['notsList'],
    queryFn: async () => {
      const data = (await axios?.get(
        '/api/notifications/my-notifications',
      )) as PaginatedResponse<'notification', NotificationAdmin>;
      console.log('our data is  ', data);
      return data;
    },
  });

  const notifications = notificationPages?.pages?.flatMap(
    (page) => page?.notification,
  );

  return (
    <Stack spacing="50px">
      <CustomBreadcrumb items={notificationsBreadcrumbs} />
      <Stack spacing="15px">
        {notifications?.map((notification) => (
          <NotificationBox
            seen={notification?.read ?? false}
            notification={notification}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default NotificationPage;
