import { Image } from '@chakra-ui/react';
import { CellProps } from 'types';
import { joinProductImage } from 'utils/images';

const ProductPhotoCell = ({ value }: CellProps) => {
  return (
    <Image
      alt="product image"
      src={joinProductImage(value)}
      w="40px"
      h="40px"
      borderRadius="xl"
    />
  );
};

export default ProductPhotoCell;
