const ActivateIcon = () => {
  return (
    <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.167 9.49992C19.167 14.5625 15.0629 18.6666 10.0003 18.6666C4.93772 18.6666 0.833658 14.5625 0.833658 9.49992C0.833658 4.43731 4.93772 0.333252 10.0003 0.333252C15.0629 0.333252 19.167 4.43731 19.167 9.49992ZM14.4972 3.70671C13.2554 2.74141 11.695 2.16659 10.0003 2.16659C5.95024 2.16659 2.66699 5.44983 2.66699 9.49992C2.66699 11.1946 3.24182 12.755 4.20712 13.9968L14.4972 3.70671ZM15.7935 5.00307C16.7588 6.24486 17.3337 7.80526 17.3337 9.49992C17.3337 13.55 14.0504 16.8333 10.0003 16.8333C8.30567 16.8333 6.74528 16.2584 5.50349 15.2931L15.7935 5.00307Z"
      />
    </svg>
  );
};

ActivateIcon.propTypes = {};

export default ActivateIcon;
