const BinDeleteIcon = () => {
  return (
    <svg viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9048 3.58331L12.6667 3.58331C12.6667 2.06453 11.4355 0.833313 9.91667 0.833313H8.08333C6.56455 0.833313 5.33333 2.06453 5.33333 3.58331L4.73134 3.58331L4.41667 3.58331L4.41558 3.58331L1.66667 3.58331C1.16041 3.58331 0.75 3.99372 0.75 4.49998C0.75 5.00624 1.16041 5.41665 1.66667 5.41665L2.58333 5.41665L2.58333 16.4166C2.58333 17.9354 3.81455 19.1666 5.33333 19.1666H12.6667C14.1855 19.1666 15.4167 17.9354 15.4167 16.4166L15.4167 5.41665L16.3333 5.41665C16.8396 5.41665 17.25 5.00624 17.25 4.49998C17.25 3.99372 16.8396 3.58331 16.3333 3.58331L13.585 3.58331L13.5833 3.58331L12.9048 3.58331ZM13.5833 5.41665H11.75L10.8333 5.41665L7.16667 5.41665L6.25 5.41665L4.41667 5.41665L4.41667 7.24998L4.41667 16.4166C4.41667 16.9229 4.82707 17.3333 5.33333 17.3333H12.6667C13.1729 17.3333 13.5833 16.9229 13.5833 16.4166L13.5833 7.24998V5.41665ZM7.16667 3.58331H8.08333H9L9.91667 3.58331H10.8333C10.8333 3.07705 10.4229 2.66665 9.91667 2.66665L8.08333 2.66665C7.57707 2.66665 7.16667 3.07705 7.16667 3.58331ZM7.16667 7.24998C7.67293 7.24998 8.08333 7.66039 8.08333 8.16665V14.5833C8.08333 15.0896 7.67293 15.5 7.16667 15.5C6.66041 15.5 6.25 15.0896 6.25 14.5833V8.16665C6.25 7.66039 6.66041 7.24998 7.16667 7.24998ZM11.75 8.16665C11.75 7.66039 11.3396 7.24998 10.8333 7.24998C10.3271 7.24998 9.91667 7.66039 9.91667 8.16665V14.5833C9.91667 15.0896 10.3271 15.5 10.8333 15.5C11.3396 15.5 11.75 15.0896 11.75 14.5833V8.16665Z"
        fill="white"
      />
    </svg>
  );
};

BinDeleteIcon.propTypes = {};

export default BinDeleteIcon;
