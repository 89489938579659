import CustomLayouts from 'layouts/CustomLayout/CustomLayouts';
import { Route, Routes } from 'react-router-dom';
import NotificationPage from '../pages/NotificationPage';
// import { lazyImport } from 'utils/lazyImport';

// import { SettingsPage } from '..';

export const NotificationsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<CustomLayouts />}>
        <Route path="" element={<NotificationPage />} />
      </Route>
    </Routes>
  );
};
