import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { User, UserListEntity } from '../types';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { extractUsers } from '../utils/extractData';

type UsersResponseType = {
  users: User[];
  meta: Meta;
};

type UsersEntityType = {
  users: UserListEntity[];
  meta: Meta;
};

export const getUsers = async (): Promise<UsersEntityType> => {
  const usersResponse = (await axios.get('/api/users')) as UsersResponseType;
  return {
    meta: usersResponse?.meta,
    users: extractUsers(usersResponse?.users),
  };
};

type QueryFnType = typeof getUsers;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ config }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['/users'],
    queryFn: () => getUsers(),
    ...config,
  });
};
