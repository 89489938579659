// import { joinProductImage, joinproductCategoryImage } from 'utils/images';

import { joinOfferImage, joinProductImage } from 'utils/images';

export const extractProducts = (
  productsResponse: Product[],
): ProductListEntity[] => {
  return (
    productsResponse?.map((product: Product) => ({
      id: product?.id,
      name: product?.name ?? '',
      price: product?.price ?? 0,
      imageUrl: joinProductImage(product?.imageName),
      description: product?.description,
      storeName: product?.store?.name ?? '',
      createdAt: product?.createdAt,
      numberOfPurcheces: product?.productLines ?? 0,
      consultData: {
        id: product?.id,
      },
    })) ?? []
  );
};

export const extractOffers = (
  offersResponse: OfferResponseListEntity[],
): OfferListEntity[] => {
  return (
    offersResponse?.map((offer: Offer) => ({
      id: offer?.id,
      title: offer?.title ?? '',
      imageUrl: joinOfferImage(offer?.imageUrl),
      pointsCost: offer?.pointsCost ?? 0,
      createdAt: offer?.createdAt,
      discount: offer?.discount ?? 0,
      type: offer.type,
      consultData: {
        id: offer?.id,
      },
    })) ?? []
  );
};
