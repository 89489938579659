const StoreProductsConsultIcon = () => {
  return (
    <svg viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M9.3125 0.789062L18.25 5.25781V16.4297L9.3125 20.8877L0.375 16.4297V5.25781L9.3125 0.789062ZM16.0264 5.6875L9.3125 2.33594L6.72363 3.625L13.3945 6.99805L16.0264 5.6875ZM9.3125 9.03906L11.8691 7.77148L5.1875 4.39844L2.59863 5.6875L9.3125 9.03906ZM1.75 6.80469V15.5703L8.625 19.0078V10.2422L1.75 6.80469ZM10 19.0078L16.875 15.5703V6.80469L10 10.2422V19.0078Z"
      />
    </svg>
  );
};

export default StoreProductsConsultIcon;
