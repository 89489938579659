const ConsultIcon = () => {
  return (
    <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.833008 9.49992C0.833008 14.5625 4.93706 18.6666 9.99967 18.6666C15.0623 18.6666 19.1663 14.5625 19.1663 9.49992C19.1663 4.43731 15.0623 0.333252 9.99967 0.333252C4.93706 0.333252 0.833008 4.43731 0.833008 9.49992ZM2.66634 9.49992C2.66634 5.44983 5.94959 2.16659 9.99967 2.16659C14.0498 2.16659 17.333 5.44983 17.333 9.49992C17.333 13.55 14.0498 16.8333 9.99967 16.8333C5.94959 16.8333 2.66634 13.55 2.66634 9.49992ZM9.99956 3.77075C9.37428 3.77075 8.87454 4.29089 8.89953 4.91567L9.10584 10.0735C9.12503 10.5532 9.51949 10.9322 9.99956 10.9322C10.4796 10.9322 10.8741 10.5532 10.8933 10.0735L11.0996 4.91567C11.1246 4.29089 10.6248 3.77075 9.99956 3.77075ZM8.68197 13.6536C8.68197 12.9416 9.2591 12.3645 9.97103 12.3645C10.683 12.3645 11.2601 12.9416 11.2601 13.6536C11.2601 14.3655 10.683 14.9426 9.97103 14.9426C9.2591 14.9426 8.68197 14.3655 8.68197 13.6536Z"
      />
    </svg>
  );
};

export default ConsultIcon;
