const StoreOffersConsultIcon = () => {
  return (
    <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6255 9.11492L10.3755 0.864919C10.0455 0.534919 9.58717 0.333252 9.08301 0.333252H2.66634C1.65801 0.333252 0.833008 1.15825 0.833008 2.16659V8.58325C0.833008 9.08742 1.03467 9.54575 1.37384 9.88492L9.62384 18.1349C9.95384 18.4649 10.4122 18.6666 10.9163 18.6666C11.4205 18.6666 11.8788 18.4649 12.2088 18.1258L18.6255 11.7091C18.9647 11.3791 19.1663 10.9208 19.1663 10.4166C19.1663 9.91242 18.9555 9.44492 18.6255 9.11492ZM10.9163 16.8424L2.66634 8.58325V2.16659H9.08301V2.15742L17.333 10.4074L10.9163 16.8424Z" />
      <path d="M4.95801 5.83325C5.7174 5.83325 6.33301 5.21764 6.33301 4.45825C6.33301 3.69886 5.7174 3.08325 4.95801 3.08325C4.19862 3.08325 3.58301 3.69886 3.58301 4.45825C3.58301 5.21764 4.19862 5.83325 4.95801 5.83325Z" />
    </svg>
  );
};

export default StoreOffersConsultIcon;
