import { useEffect } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { clientOrderProductsColumns } from '../variables/table';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import TableComponent from 'components/table/Table';
import { FormattedMessage } from 'react-intl';
import usePage from 'hooks/usePage';
import { useClientOrderProducts } from '../api/getClientOrderProducts';

const ClientOrderProducts = () => {
  const { page } = usePage();
  const { orderId } = useParams();

  const { data: ordersData, refetch: refetchOrderProducts } =
    useClientOrderProducts({
      query: {
        limit: 8,
        page: Number(page ?? '1'),
        orderId: orderId as string,
      },
    });

  useEffect(() => {
    if (Number(page) !== ordersData?.meta?.currentPage) {
      refetchOrderProducts();
    }
  }, [page, orderId]);

  const breadItems = [
    {
      name: <FormattedMessage id="users" />,
    },
    {
      name: <FormattedMessage id="clients" />,
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breadItems} />
      </HStack>
      <TableComponent<ReservationProductTable>
        name="clientUsedOrderProductsHistories"
        // name="users"
        // selectRow={navigateOrderDetails}
        hideSelection={true}
        paginated={true}
        data={ordersData?.products ?? []}
        tableColumns={clientOrderProductsColumns}
        pageIndex={Number(page ?? '1')}
        pageCount={ordersData?.meta?.totalPages ?? 1}
        // TableUtils={UsersUtils}
        //
        // searching={setSearch}
        // detailsIcon={true}
      />
    </Stack>
  );
};

export default ClientOrderProducts;
