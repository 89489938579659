import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { useQuery } from '@tanstack/react-query';

export const getSeller = async (sellerId?: string): Promise<Store | null> => {
  return sellerId ? await axios.get(`/api/stores/${sellerId}`) : null;
};

type QueryFnType = typeof getSeller;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  sellerId?: string;
};

export const useSeller = ({ config, sellerId }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`sellers/${sellerId}`],
    queryFn: () => getSeller(sellerId),
    ...config,
  });
};
