import { Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { CellProps } from 'types';

const ClientPaymentPriceCell = ({ value }: CellProps) => {
  return (
    <Text fontSize="14px" lineHeight="21px" color="gray.800" maxW="200px">
      {value?.amount && (
        <FormattedNumber
          style="currency"
          value={value?.amount}
          currency={value?.currency}
        />
      )}
    </Text>
  );
};

export default ClientPaymentPriceCell;
