import { Partner, PartnerResponse } from '../types';

export const extractPartners = (
  partnerRespose: PartnerResponse[],
): Partner[] => {
  let id = 0;
  return (
    partnerRespose?.map((partner: PartnerResponse) => {
      id = id + 1;
      return {
        id: `id${id}`,
        fullName: partner?.user ?? '',
        storeName: partner?.storeName ?? '',
        phoneNumber: partner?.phoneNumber ?? '',
      };
    }) ?? []
  );
};

export const extractEventOrganizers = (
  organizersResponse: EventOrganizer[],
): EventOrganizerTable[] => {
  return (
    organizersResponse?.map((organizer: EventOrganizer) => {
      return {
        id: organizer?.user_id,
        numberOfEvents: organizer?.numberOfEvents,
        organizerName: organizer?.displayName,
        subscriptionDate: organizer?.subscriptions?.[0]?.createdAt,
        phoneNumber: organizer?.phoneNumber,
        address: organizer?.contactDetails?.address,
        zip: organizer?.contactDetails?.zip,
        email: organizer?.contactDetails?.email,
        status: organizer?.status,
        consultData: {
          id: organizer?.user_id,
          status: organizer?.status,
        },
      };
    }) ?? []
  );
};
