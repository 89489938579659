import { Text } from '@chakra-ui/react';
import { CellProps } from 'types';

const DiscountOfferCell = ({ value }: CellProps) => {
  return (
    <Text fontSize="14px" color="gray.800" maxW="200px">
      {(value / 100).toFixed(0)}%
    </Text>
  );
};

export default DiscountOfferCell;
