import { Text } from '@chakra-ui/react';
import { CellProps } from 'types';
import BaseEditorOutput from '../../../../../components/Editor/BaseEditorOutput';

const ProductDescriptionCell = ({ value }: CellProps) => {
  return (
    <Text fontSize="14px" color="gray.800" maxW="200px" noOfLines={3}>
      <BaseEditorOutput data={JSON.parse(value)} />
    </Text>
  );
};

export default ProductDescriptionCell;
