import CustomLayouts from 'layouts/CustomLayout/CustomLayouts';
import { Route, Routes } from 'react-router-dom';
import ListClients from '../pages/ListClients';
import ClientEventsHistory from '../pages/ClientEventsHistory';
import ClientCouponsHistory from '../pages/ClientCouponsHistory';
import ClientOrdersHistory from '../pages/ClientOrdersHistory';
import ClientInvitedFriendsHistory from '../pages/InvitedFriendsHistory';
import ClientOrderProducts from '../pages/ClientOrderProducts';

//components

export const ClientsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomLayouts />}>
        <Route path="" element={<ListClients />} />
      </Route>
      <Route path="/:clientId" element={<CustomLayouts />}>
        <Route path="history">
          <Route path="events" element={<ClientEventsHistory />} />
          <Route path="coupons" element={<ClientCouponsHistory />} />
          <Route path="orders">
            <Route path=":orderId" element={<ClientOrderProducts />} />
            <Route path="" element={<ClientOrdersHistory />} />
          </Route>
          <Route path="friends" element={<ClientInvitedFriendsHistory />} />
        </Route>
      </Route>
    </Routes>
  );
};
