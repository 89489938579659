import React from 'react';

const YoutubeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.187 28.926">
      <path
        id="Path_176"
        data-name="Path 176"
        d="M22.426,42.324c-7.844-.144-10.523-.274-12.17-.614a5.349,5.349,0,0,1-2.792-1.452,5.878,5.878,0,0,1-1.323-2.551,16.682,16.682,0,0,1-.565-3.744,88.938,88.938,0,0,1,0-12.137c.252-2.242.374-4.9,2.049-6.456a5.5,5.5,0,0,1,2.744-1.388,151.523,151.523,0,0,1,15.607-.549,151.8,151.8,0,0,1,15.608.549,5.369,5.369,0,0,1,3.212,1.9c1.528,2.4,1.555,5.393,1.71,7.731.064,1.114.064,7.44,0,8.554-.241,3.7-.436,5-.984,6.358a4.687,4.687,0,0,1-1.13,1.808,5.414,5.414,0,0,1-2.873,1.469c-6.788.51-12.552.621-19.093.517ZM32.82,27.476c-3.776-2.018-7.392-3.889-11.088-5.826V33.238c3.889-2.114,7.99-4.051,11.1-5.778l-.016.016Z"
        transform="translate(-5.368 -13.434)"
        fill="#cd201f"
      />
    </svg>
  );
};

export default YoutubeIcon;
