import { Client, ClientResponse } from '../types';

export const extractClients = (clientRespose: ClientResponse[]): Client[] => {
  return (
    clientRespose?.map((client: ClientResponse) => {
      return {
        id: client?.id,
        fullName: client?.fullName ?? '',
        subscriptionDate: client?.subscriptionDate ?? '',
        phoneNumber: client?.phoneNumber ?? '',
        score: client?.score ?? 0,
        tickets: client?.tickets ?? 0,
        status: client?.status,
        consultData: {
          id: client?.id,
        },
      };
    }) ?? []
  );
};

export const extractEventReservationsHistory = (
  clientRespose: EventReservation[],
): EventReservationTable[] => {
  return (
    clientRespose?.map((reservation: EventReservation) => {
      return {
        id: `${reservation?.id}`,
        title: reservation?.event?.title,
        createdAt: reservation?.createdAt,
        price: {
          amount: reservation?.payements?.[0]?.amount,
          currency: reservation?.payements?.[0]?.currency,
        },
        paymentMethod: reservation?.payements?.[0]?.paymentMethod,
      };
    }) ?? []
  );
};

export const extractclientCouponsHistory = (
  couponsResponse: ClientCoupon[],
): ClientCouponTable[] => {
  return (
    couponsResponse?.map((coupon) => {
      return {
        id: `${coupon?.id}`,
        eventTitle: coupon?.event?.title,
        promoCode: coupon?.coupon?.promoCode,
        useDate: coupon?.useDate,
      };
    }) ?? []
  );
};

export const extractclientOrdersHistory = (
  ordersResponse: ClientOrder[],
): ClientOrderTable[] => {
  return (
    ordersResponse?.map((order) => {
      return {
        id: `${order?.id}`,
        storeName: order?.store?.name ?? '',
        points: order?.points,
        createdAt: order?.createdAt,
        totalQuantity: order?.totalQuantity,
        consultData: {
          id: order?.id,
        },
      };
    }) ?? []
  );
};

export const extractclientInvitedFriendsHistory = (
  friendsResponse: ClientInvitedFriend[],
): ClientInvitedFriendTable[] => {
  return (
    friendsResponse?.map((friend, key) => {
      return {
        id: `1234${key + 1}`,
        userName: friend?.displayName ?? '',
        earnedPoints: 100,
        createdAt: friend?.createdAt,
      };
    }) ?? []
  );
};

export const extractclientOrderProductsHistory = (
  productsResponse: Product[],
): ReservationProductTable[] => {
  return (
    productsResponse?.map((product) => {
      return {
        id: `${product?.id}`,
        productId: `${product?.id}`,
        productName: product?.name,
        productDescription: product?.description,
        quantity: 5,
        productPicture: product?.imageName,
        price: product?.price,
      };
    }) ?? []
  );
};
