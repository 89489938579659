import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractEventReservationsHistory } from '../utils/extactData';

type ClientEventReservationResponseProps = {
  meta: Meta;
  reservations: EventReservation[];
};
type ClientEventReservationProps = {
  meta: Meta;
  reservations: EventReservationTable[];
};

type GetClientEventReservationsProps = {
  limit?: number;
  page?: number;
  search?: string;
  clientId: string;
};

export const getClientEventReservations = async ({
  limit = 8,
  page = 1, // search = '',
  clientId,
}: GetClientEventReservationsProps): Promise<ClientEventReservationProps | null> => {
  if (!clientId) {
    return null;
  }

  console.log(`our client id => ${clientId} pag => =  ${page}  `);

  const eventReservationReseponse = (await axios.get(
    `/api/events/reservations/clients/${clientId}`,
    {
      params: {
        limit,
        page,
        // search,
      },
    },
  )) as ClientEventReservationResponseProps;

  console.log('our event reservations ara e , => ', eventReservationReseponse);

  const reservations = extractEventReservationsHistory(
    eventReservationReseponse?.reservations as EventReservation[],
  );

  return { meta: eventReservationReseponse?.meta, reservations };
};

type QueryFnType = typeof getClientEventReservations;

export type UseClietEventsOptions = {
  config?: QueryConfig<QueryFnType>;
  query: {
    limit?: number;
    page?: number;
    search?: string;
    clientId: string;
  };
};

export const useClientEventReservations = ({
  config,
  query: { limit, page, search, clientId },
}: UseClietEventsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [
      `ClientEventReservationsEventsHistory/${clientId}${search}/${page}/${limit}`,
    ],
    queryFn: () =>
      getClientEventReservations({
        limit,
        page,
        search,
        clientId,
      }),
    ...config,
  });
};
