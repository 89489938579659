import React from 'react';

const NotificationNavbarRingIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.7369 18.6836H9.3419C9.3419 19.1791 9.53874 19.6543 9.88912 20.0047C10.2395 20.3551 10.7147 20.5519 11.2102 20.5519C11.7057 20.5519 12.181 20.3551 12.5313 20.0047C12.8817 19.6543 13.0786 19.1791 13.0786 18.6836H18.6836C18.9313 18.6836 19.1689 18.5851 19.3441 18.41C19.5193 18.2348 19.6177 17.9972 19.6177 17.7494C19.6177 17.5016 19.5193 17.264 19.3441 17.0888C19.1689 16.9137 18.9313 16.8152 18.6836 16.8152V10.2761C18.6836 8.29402 17.8962 6.39315 16.4947 4.99162C15.0932 3.5901 13.1923 2.80273 11.2102 2.80273C9.22818 2.80273 7.32731 3.5901 5.92579 4.99162C4.52427 6.39315 3.7369 8.29402 3.7369 10.2761V16.8152C3.48914 16.8152 3.25154 16.9137 3.07635 17.0888C2.90116 17.264 2.80273 17.5016 2.80273 17.7494C2.80273 17.9972 2.90116 18.2348 3.07635 18.41C3.25154 18.5851 3.48914 18.6836 3.7369 18.6836ZM5.60523 10.2761C5.60523 8.78953 6.19576 7.36388 7.2469 6.31273C8.29804 5.26159 9.7237 4.67107 11.2102 4.67107C12.6968 4.67107 14.1224 5.26159 15.1736 6.31273C16.2247 7.36388 16.8152 8.78953 16.8152 10.2761V16.8152H5.60523V10.2761Z"
        fill="inherit"
      />
    </svg>
  );
};

export default NotificationNavbarRingIcon;
