export const handleToogleSearchParams = (
  searchParams: any,
  name: string,
  currentValue: any,
  willingValue: any,
) => {
  const params = new URLSearchParams(searchParams.toString());
  console.log('our data is  => ', params);
  if (currentValue === willingValue) {
    params.delete(name);
  } else {
    params.set(name, willingValue);
  }
  return params.toString();
};

export const deleteSearchParams = (searchParams: any, name: string) => {
  const params = new URLSearchParams(searchParams.toString());
  params.delete(name);

  return params.toString();
};

export const setSearchParams = (
  searchParams: any,
  name: string,
  value: any,
) => {
  const params = new URLSearchParams(searchParams.toString());
  params.set(name, value);

  return params.toString();
};
