const BanIcon = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d="M19.1666 9.49967C19.1666 14.5623 15.0626 18.6663 9.99996 18.6663C4.93735 18.6663 0.833292 14.5623 0.833292 9.49967C0.833292 4.43706 4.93735 0.333008 9.99996 0.333008C15.0626 0.333008 19.1666 4.43706 19.1666 9.49967ZM14.4968 3.70646C13.255 2.74116 11.6946 2.16634 9.99996 2.16634C5.94987 2.16634 2.66663 5.44959 2.66663 9.49967C2.66663 11.1943 3.24145 12.7547 4.20676 13.9965L14.4968 3.70646ZM15.7932 5.00282C16.7585 6.24462 17.3333 7.80502 17.3333 9.49967C17.3333 13.5498 14.05 16.833 9.99996 16.833C8.30531 16.833 6.74491 16.2582 5.50312 15.2929L15.7932 5.00282Z"
    />
  );
};

BanIcon.propTypes = {};

export default BanIcon;
