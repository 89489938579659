import {
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

//types
import { TableMenuItem } from 'types';

//api
import { useDeletePartner } from 'features/partners/api/deletePartner';

// icons
import DeleteBinTableIcon from 'assets/icons/table/DeleteBinTableIcon';
import TableMenuIcon from 'assets/icons/table/TableMenuIcon';
import BanIcon from 'assets/icons/table/BanIcon';

//utils
import { defaultFn } from 'utils/functions';
import StoreConsultIcon from 'assets/icons/table/StoreConsultIcon';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import StoreProductsConsultIcon from 'assets/icons/table/StoreProductsConsultIcon';
import StoreOffersConsultIcon from 'assets/icons/table/StoreOffersConsultIcon';
import StoreReviewsConsult from 'assets/icons/table/StoreReviewsConsult';
import { useBanUsers } from 'features/users/api/banUsers';

const SellerMenuCell = ({ value }: any) => {
  const intl = useIntl();
  const { mutate: deleteSeller } = useDeletePartner();
  const { mutate: banUsers } = useBanUsers();
  const navigate = useNavigate();

  const menuItems: TableMenuItem[] = [
    {
      title: intl.formatMessage({ id: 'viewStore' }),
      Icon: StoreConsultIcon,
      onClick: () => {
        navigate(`/users/partners/sellers/${value?.id}`);
      },
    },
    {
      title: intl.formatMessage({ id: 'viewProducts' }),
      Icon: StoreProductsConsultIcon,
      onClick: () => {
        navigate(`/users/partners/sellers/${value?.id}/products`);
      },
    },
    {
      title: intl.formatMessage({ id: 'viewReviews' }),
      Icon: StoreReviewsConsult,
      onClick: () => {
        navigate(`/users/partners/sellers/${value?.id}/reviews`);
      },
    },
    {
      title: intl.formatMessage({ id: 'viewOffers' }),
      Icon: StoreOffersConsultIcon,
      onClick: () => {
        navigate(`/users/partners/sellers/${value?.id}/offers`);
      },
    },
    {
      title: intl.formatMessage({ id: 'delete' }),
      Icon: DeleteBinTableIcon,
      onClick: () => {
        deleteSeller({ partnerId: value });
      },
    },
    {
      title: intl.formatMessage({ id: 'ban' }),
      Icon: BanIcon,
      onClick: () => {
        banUsers([value?.user_id]);
      },
    },
  ];

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Icon fill="primary.500" viewBox="0 0 4 18">
            <TableMenuIcon />
          </Icon>
        </PopoverTrigger>
        <PopoverContent maxW={'fit-content'}>
          <Menu isOpen={true}>
            <MenuList px="10px" py="5px" rowGap="10px">
              {menuItems?.map((item: TableMenuItem, key: any) => (
                <MenuItem
                  key={key}
                  borderRadius="10px"
                  _hover={{
                    bg: 'gray.300',
                  }}
                  columnGap="20px"
                  py="15px"
                  px="20px"
                  onClick={item?.onClick ?? defaultFn}
                >
                  <Icon viewBox="0 0 19 20" fill="black.200" stroke="black.200">
                    <item.Icon />
                  </Icon>
                  <Text
                    _firstLetter={{ textTransform: 'capitalize' }}
                    color="gray.800"
                  >
                    {item?.title}{' '}
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default SellerMenuCell;
