import { Icon } from '@chakra-ui/react';
import DownloadReceiptIcon from 'assets/icons/event/DownloadReceiptIcon';

const EventReservationReceiptCell = () => {
  return (
    <Icon>
      <DownloadReceiptIcon />
    </Icon>
  );
};

export default EventReservationReceiptCell;
