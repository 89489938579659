import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractclientCouponsHistory } from '../utils/extactData';

type ClientCouponResponseProps = {
  meta: Meta;
  coupons: ClientCoupon[];
};
type ClientCouponProps = {
  meta: Meta;
  coupons: ClientCouponTable[];
};

type GetClientCouponsProps = {
  limit?: number;
  page?: number;
  search?: string;
  clientId: string;
};

export const getClientCoupons = async ({
  limit = 8,
  page = 1, // search = '',
  clientId,
}: GetClientCouponsProps): Promise<ClientCouponProps | null> => {
  if (!clientId) {
    return null;
  }

  console.log(`our client id => ${clientId} pag => =  ${page}  `);

  const clientCouponReseponse = (await axios.get(
    `/api/event-coupons/client/${clientId}`,
    {
      params: {
        limit,
        page,
        // search,
      },
    },
  )) as ClientCouponResponseProps;

  console.log('our event reservations ara e , => ', clientCouponReseponse);

  const coupons = extractclientCouponsHistory(
    clientCouponReseponse?.coupons as ClientCoupon[],
  );

  return { meta: clientCouponReseponse?.meta, coupons };
};

type QueryFnType = typeof getClientCoupons;

export type UseClietCouponsOptions = {
  config?: QueryConfig<QueryFnType>;
  query: {
    limit?: number;
    page?: number;
    search?: string;
    clientId: string;
  };
};

export const useClientCoupons = ({
  config,
  query: { limit, page, search, clientId },
}: UseClietCouponsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`ClientCouponsHistory/${clientId}${search}/${page}/${limit}`],
    queryFn: () =>
      getClientCoupons({
        limit,
        page,
        search,
        clientId,
      }),
    ...config,
  });
};
