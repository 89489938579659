import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractclientOrderProductsHistory } from '../utils/extactData';

//TODO: handle chage dto
type ClientOrderResponseProps = {
  meta: Meta;
  products: Product[];
};
type ClientOrderProps = {
  meta: Meta;
  products: ReservationProductTable[];
};

type GetClientOrderProductsProps = {
  limit?: number;
  page?: number;
  search?: string;
  orderId: string;
};

export const getClientOrderProducts = async ({
  limit = 8,
  page = 1, // search = '',
  orderId,
}: GetClientOrderProductsProps): Promise<ClientOrderProps | null> => {
  if (!orderId) {
    return null;
  }

  console.log(`our client id => ${orderId} pag => =  ${page}  `);

  const clientOrderReseponse = (await axios.get(
    `/api/product/order-products/${orderId}`,
    {
      params: {
        limit,
        page,
        // search,
      },
    },
  )) as ClientOrderResponseProps;

  console.log('our event reservations ara e , => ', clientOrderReseponse);

  const products = extractclientOrderProductsHistory(
    clientOrderReseponse?.products as Product[],
  );

  return { meta: clientOrderReseponse?.meta, products };
};

type QueryFnType = typeof getClientOrderProducts;

export type UseClietOrderProductsOptions = {
  config?: QueryConfig<QueryFnType>;
  query: {
    limit?: number;
    page?: number;
    search?: string;
    orderId: string;
  };
};

export const useClientOrderProducts = ({
  config,
  query: { limit, page, search, orderId },
}: UseClietOrderProductsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [
      `ClientOrderProductsHistory/${orderId}${search}/${page}/${limit}`,
    ],
    queryFn: () =>
      getClientOrderProducts({
        limit,
        page,
        search,
        orderId,
      }),
    ...config,
  });
};
