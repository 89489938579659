import { PropsWithChildren } from 'react';
import FieldWrapper, {
  FieldWrapperPassThroughProps,
} from 'components/form/FieldWrapper';
import InputShower from './InputShower';
import If from 'common/If';
import { Flex } from '@chakra-ui/react';

type InputFieldProps = FieldWrapperPassThroughProps & {
  inputStyle?: any;
  value: socialMediaUrl[];
  type?: string;
  inputLeftAddon?: any;
  placeholder?: string;
  outlet?: any;
  wrapperStyles?: any;
  [rest: string]: any;
};

const SocialMediaUrls = ({
  error,
  label,
  value,
  wrapperStyles = {},
}: PropsWithChildren<InputFieldProps>) => {
  return (
    <If condition={value?.length > 0}>
      <FieldWrapper wrapperStyles={wrapperStyles} error={error} label={label}>
        <Flex flexWrap="wrap" columnGap={8} rowGap={4} py={4}>
          {value?.map((socialMediaUrl: socialMediaUrl) => {
            return (
              <InputShower
                key={socialMediaUrl?.id}
                label={socialMediaUrl?.name}
                defaultValue={socialMediaUrl?.url}
                wrapperStyles={{
                  w: '400px',
                }}
                inputStyle={{
                  variant: 'primary',
                  fontSize: 'xs',
                  size: 'lg',
                  fontWeight: 'normal',
                }}
              />
            );
          })}
        </Flex>
      </FieldWrapper>
    </If>
  );
};

export default SocialMediaUrls;
