import { Icon } from '@chakra-ui/react';
import ConsultIcon from 'assets/icons/table/ConsultIcon';
import { Link, useParams } from 'react-router-dom';
import { CellProps } from 'types';

const ClientOrderMenuCell = ({ value }: CellProps) => {
  const { clientId } = useParams();
  return (
    <Link to={`/users/clients/${clientId}/history/orders/${value?.id}`}>
      <Icon fill="black.200" fontSize="xl">
        <ConsultIcon />
      </Icon>
    </Link>
  );
};

export default ClientOrderMenuCell;
