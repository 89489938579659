import { Center, Icon, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import NotificationNavbarRingIcon from 'assets/icons/notifications/NotificationNavbarRingIcon';
import { axios } from 'lib/axios';

import React from 'react';
import { Link } from 'react-router-dom';

const NotificationsRing = () => {
  const { data: count } = useQuery({
    queryKey: ['notscount'],
    queryFn: async () => {
      const countData = (await axios?.get(
        '/api/notifications/unread-count',
      )) as any;
      return countData?.count ?? 0;
    },
  });
  return (
    <Link to="/notifications">
      <Center
        bg="badge.green.100"
        borderRadius="full"
        padding="10px"
        position="relative"
      >
        <Center
          borderRadius="full"
          bg="primary.bg.100"
          position="absolute"
          top="-10px"
          right="-10px"
          p="5px"
        >
          <Center
            width="fit-content"
            minW="15px"
            height="15px"
            borderRadius="full"
            bg="primary.500"
            p="2px"
          >
            <Text p="0" fontSize="xs" fontWeight="100" color="white">
              {count ?? 0}
            </Text>
          </Center>
        </Center>
        <Icon fill="primary.500" fontSize="20px">
          <NotificationNavbarRingIcon />
        </Icon>
      </Center>
    </Link>
  );
};

export default NotificationsRing;
