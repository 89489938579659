const Clients = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82286 21.1767C1.82286 19.2345 2.5944 17.3718 3.96774 15.9985C5.34109 14.6251 7.20374 13.8536 9.14594 13.8536C11.0881 13.8536 12.9508 14.6251 14.3241 15.9985C15.6975 17.3718 16.469 19.2345 16.469 21.1767H1.82286ZM9.14594 12.9382C6.11144 12.9382 3.65363 10.4804 3.65363 7.44592C3.65363 4.41142 6.11144 1.95361 9.14594 1.95361C12.1804 1.95361 14.6382 4.41142 14.6382 7.44592C14.6382 10.4804 12.1804 12.9382 9.14594 12.9382Z"
        stroke="#04103B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5535 2.86914C17.8293 2.86914 19.6727 5.09287 19.6727 7.83837C19.6727 10.5839 17.8293 12.7204 15.5535 12.7204C17.0101 12.7204 18.4071 13.4185 19.4371 14.661C20.4671 15.9036 21.0458 17.5888 21.0458 19.3461H18.6919"
        stroke="#04103B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Clients;
