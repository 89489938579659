import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';

type ReviewProps = { meta: Meta; reviews: Review[] };

type getReviewsQueryType = {
  page?: number;
  limit?: number;
  sellerId?: string;
};

export const getSellerReviews = async ({
  page = 1,
  limit = 5,
  sellerId,
}: getReviewsQueryType): Promise<ReviewProps | null> => {
  if (!sellerId) {
    return null;
  }
  const sellerReviewsResponse = (await axios.get(
    `/api/stores/myReviews/${sellerId}`,
    {
      params: {
        page,
        limit,
      },
    },
  )) as ReviewProps;

  return sellerReviewsResponse;
};

type QueryFnType = typeof getSellerReviews;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: getReviewsQueryType;
};

export const useSellerReviews = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`partners/sellerReview/${query?.sellerId}`],
    queryFn: () => getSellerReviews(query),
    ...config,
  });
};
