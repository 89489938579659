// id : number ,
// partnerName: string,
// storeName: string,

import Cell from 'components/table/components/cell';
import PartnerNameCell from '../components/table/PartnerNameCell';
import UserStatus from 'features/users/components/table/UserStatus';
import SellerMenuCell from '../components/table/SellerMenuCell';
import DateCell from 'components/table/components/date';
import ProductPhotoCell from '../components/table/ProductPhotoCell';
import ProductNameCell from 'features/services/components/table/product/ProductNameCell';
import ProductDescriptionCell from 'features/services/components/table/product/ProductDescriptionCell';
import ProductPriceCell from 'features/services/components/table/product/ProductPriceCell';
import ProductMenuCell from '../components/table/ProductMenuCell';
import DiscountOfferCell from 'features/services/components/table/offer/DiscountOfferCell';
import OfferPhotoCell from 'features/services/components/table/offer/OfferPhotoCell';
import OfferNameCell from 'features/services/components/table/offer/OfferNameCell';
import OfferTypeCell from 'features/services/components/table/offer/OfferTypeCell';
import OfferMenuCell from '../components/table/OfferMenuCell';

// orderNumber: number
export const sellersColumns = [
  {
    Header: 'user',
    accessor: 'displayName',
    Cell: PartnerNameCell,
  },
  {
    Header: 'subcriptionDate',
    accessor: 'subscriptionDate',
    Cell: DateCell,
  },
  // {
  //   Header: 'company',
  //   accessor: 'storeName',
  //   Cell: Cell,
  // },
  {
    Header: 'phoneNumber',
    accessor: 'phoneNumber',
    Cell: Cell,
  },

  {
    Header: 'email',
    accessor: 'email',
    Cell: Cell,
  },
  // {
  //   Header: 'address',
  //   accessor: 'address',
  //   Cell: Cell,
  // },
  // {
  //   Header: 'zip',
  //   accessor: 'zip',
  //   Cell: Cell,
  // },
  {
    Header: 'city',
    accessor: 'town',
    Cell: Cell,
  },

  {
    Header: 'products',
    accessor: 'numberOfProducts',
    Cell: Cell,
  },
  {
    Header: 'status',
    accessor: 'status',
    Cell: UserStatus,
  },
  // {
  //     Header :"tickets",
  //     accessor : "tickets",
  //     Cell: Cell
  // },
  {
    Header: '',
    accessor: 'consultData',
    Cell: SellerMenuCell,
  },
];

// orderNumber: number
export const productsColumns = [
  {
    Header: 'picture',
    accessor: 'imageName',
    Cell: ProductPhotoCell,
  },
  {
    Header: 'serviceTitle',
    accessor: 'name',
    Cell: ProductNameCell,
  },
  {
    Header: 'productDescription',
    accessor: 'description',
    Cell: ProductDescriptionCell,
  },
  {
    Header: 'publishing',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'price',
    accessor: 'price',
    Cell: ProductPriceCell,
  },
  {
    Header: 'purchase',
    accessor: 'numberOfPurcheces',
    Cell: Cell,
  },

  {
    Header: '',
    accessor: 'consultData',
    Cell: ProductMenuCell,
  },
];

export const offersColumns = [
  {
    Header: 'picture',
    accessor: 'imageUrl',
    Cell: OfferPhotoCell,
  },
  {
    Header: 'serviceTitle',
    accessor: 'title',
    Cell: OfferNameCell,
  },
  {
    Header: 'offerType',
    accessor: 'type',
    Cell: OfferTypeCell,
  },
  {
    Header: 'publishing',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'points',
    accessor: 'pointsCost',
    Cell: Cell,
  },
  {
    Header: 'reduction',
    accessor: 'discount',
    Cell: DiscountOfferCell,
  },
  {
    Header: '',
    accessor: 'consultData',
    Cell: OfferMenuCell,
  },
];
