import React from 'react';
const PauseTableIcon = () => {
  return (
    <path
      stroke="none"
      d="M8 14C8.55 14 9 13.55 9 13V7C9 6.45 8.55 6 8 6C7.45 6 7 6.45 7 7V13C7 13.55 7.45 14 8 14ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM12 14C12.55 14 13 13.55 13 13V7C13 6.45 12.55 6 12 6C11.45 6 11 6.45 11 7V13C11 13.55 11.45 14 12 14Z"
    />
  );
};

export default PauseTableIcon;
