import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { User, UserListEntity } from '../types';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { extractUsers } from '../utils/extractData';

type UsersResponseType = {
  users: User[];
  meta: Meta;
};

type UsersEntityType = {
  users: UserListEntity[];
  meta: Meta;
};

export const getNewUsers = async (limit: number): Promise<UsersEntityType> => {
  const usersResponse = (await axios.get(
    `/api/users/admin/new-clients/${limit}`,
  )) as UsersResponseType;

  return {
    meta: usersResponse?.meta,
    users: extractUsers(usersResponse?.users),
  };

  // return({
  //   ...usersResponse,
  //   users:  usersResponse?.users?.map
  // })
};

type QueryFnType = typeof getNewUsers;

type NewUserQuery = {
  limit: number;
};

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: NewUserQuery;
};

export const useNewUsers = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`/newUsers/${query?.limit}`],
    queryFn: () => getNewUsers(query?.limit),
    ...config,
  });
};
