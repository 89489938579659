import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

//icons
import DeleteBinTableIcon from 'assets/icons/table/DeleteBinTableIcon';
import { useDeleteOffers } from 'features/seller/api/deleteOffers';
import { FormattedMessage } from 'react-intl';
// import { useDeleteEvent } from 'features/events/api/deleteEvents';

const OfferMenuCell = ({ value }: any) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutate: deleteProduts, isLoading: isDeletingOffer } =
    useDeleteOffers();

  const handleDeleteOffer = () => {
    deleteProduts([value?.id as string]);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent
          minW={{
            base: '100%',
            md: '600px',
          }}
          borderRadius="xl"
          p={8}
        >
          <ModalBody display="flex" flexDir="column" alignItems="center">
            <Text
              textAlign="center"
              color="primary.500"
              fontSize="xl"
              fontWeight="normal"
              _firstLetter={{
                textTransform: 'capitalize',
              }}
            >
              <FormattedMessage id="deleteOffer" />
            </Text>
            <Text
              fontSize="md"
              py="2"
              color="gray.800"
              _firstLetter={{
                textTransform: 'capitalize',
              }}
            >
              <FormattedMessage id="wannaDeleteOffer?" />
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center" gap="15px">
            <Button
              variant="primaryOutline"
              borderWidth="2px"
              w="100px"
              onClick={onClose}
              // variant="primaryOutline"
            >
              <Text textTransform="capitalize">
                <FormattedMessage id="cancel" />
              </Text>
            </Button>
            <Button
              onClick={handleDeleteOffer}
              isLoading={isDeletingOffer}
              variant="primaryFill"
              w="100px"
            >
              <Text textTransform="capitalize">
                <FormattedMessage id="delete" />
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <
                      borderRadius="10px"
                      _hover={{
                        bg: 'gray.300',
                      }}
                      columnGap="20px"
                      py="15px"
                      px="20px"
                      onClick={item?.onClick ?? defaultFn}
                    > */}
      <Icon onClick={onOpen} w="25px" h="25px" fill="black.200">
        <DeleteBinTableIcon />
      </Icon>
      {/* </MenuItem> */}
    </>
  );
};

export default OfferMenuCell;
