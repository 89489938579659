import { Stack } from '@chakra-ui/react';
import TableComponent from 'components/table/Table';
import { useNewUsers } from '../api/getNewUsers';
import { UserListEntity } from '../types';
import { usersColumns } from '../variables/table';
import UsersUtils from '../components/table/UsersUtils';

const ListNewUsers = () => {
  const { data: users } = useNewUsers({
    query: {
      limit: 4,
    },
  });

  return (
    <Stack py="15px" spacing="10px">
      <TableComponent<UserListEntity>
        name="newUsers"
        // name="users"
        // selectRow={navigateOrderDetails}
        data={users?.users ?? []}
        tableColumns={usersColumns}
        paginated={false}
        TableUtils={UsersUtils}
        //
        // searching={setSearch}
        // detailsIcon={true}
      />
    </Stack>
  );
};

export default ListNewUsers;
