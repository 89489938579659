import { MenuItem, MenuList, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

const TypeHistorySelect = () => {
  const { clientId } = useParams();
  const typeHistoriesSelect = [
    {
      label: 'eventsHistory',
      link: `/users/clients/${clientId}/history/events`,
    },
    {
      label: 'invitedFriendsHistory',
      link: `/users/clients/${clientId}/history/friends`,
    },
    {
      label: 'usedCouponsHistory',
      link: `/users/clients/${clientId}/history/coupons`,
    },
    {
      label: 'ordersAndPoints',
      link: `/users/clients/${clientId}/history/orders`,
    },
  ];

  return (
    <MenuList borderRadius="xl">
      {typeHistoriesSelect?.map((select) => (
        <Link to={select?.link}>
          <MenuItem>
            <Text
              _firstLetter={{ textTransform: 'capitalize' }}
              fontWeight="semibold"
              fontSize="sm"
            >
              <FormattedMessage id={select?.label} />
            </Text>
          </MenuItem>
        </Link>
      ))}
    </MenuList>
  );
};

export default TypeHistorySelect;
