const StatisticsIcon = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d="M0 0.625C0 0.279822 0.279822 0 0.625 0C0.970178 0 1.25 0.279822 1.25 0.625V18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20H1C0.447715 20 0 19.5523 0 19V0.625ZM12.5 4.375C12.5 4.20924 12.5658 4.05027 12.6831 3.93306C12.8003 3.81585 12.9592 3.75 13.125 3.75H18.125C18.2908 3.75 18.4497 3.81585 18.5669 3.93306C18.6842 4.05027 18.75 4.20924 18.75 4.375V9.375C18.75 9.54076 18.6842 9.69973 18.5669 9.81694C18.4497 9.93415 18.2908 10 18.125 10C17.9592 10 17.8003 9.93415 17.6831 9.81694C17.5658 9.69973 17.5 9.54076 17.5 9.375V6.125L12.9838 11.6463C12.9285 11.7137 12.8597 11.7689 12.7818 11.8082C12.7039 11.8475 12.6186 11.87 12.5315 11.8744C12.4444 11.8787 12.3573 11.8648 12.2759 11.8335C12.1945 11.8022 12.1205 11.7541 12.0588 11.6925L8.825 8.45875L4.255 14.7425C4.15503 14.8696 4.00967 14.953 3.84948 14.9752C3.68929 14.9974 3.52673 14.9567 3.39596 14.8615C3.2652 14.7663 3.17641 14.6242 3.14826 14.4649C3.12011 14.3057 3.15479 14.1417 3.245 14.0075L8.245 7.1325C8.29807 7.05939 8.36636 6.99866 8.44516 6.95448C8.52396 6.91031 8.61141 6.88374 8.70146 6.87661C8.79152 6.86948 8.88205 6.88196 8.96682 6.91319C9.05159 6.94442 9.12859 6.99365 9.1925 7.0575L12.4538 10.32L16.8062 5H13.125C12.9592 5 12.8003 4.93415 12.6831 4.81694C12.5658 4.69973 12.5 4.54076 12.5 4.375Z"
    />
  );
};

StatisticsIcon.propTypes = {};

export default StatisticsIcon;
