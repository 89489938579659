import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractclientInvitedFriendsHistory } from '../utils/extactData';

type ClientInvitedFriendResponseProps = {
  meta: Meta;
  users: ClientInvitedFriend[];
};
type ClientInvitedFriendProps = {
  meta: Meta;
  users: ClientInvitedFriendTable[];
};

type GetClientInvitedFriendsProps = {
  limit?: number;
  page?: number;
  search?: string;
  clientId: string;
};

export const getClientInvitedFriends = async ({
  limit = 8,
  page = 1, // search = '',
  clientId,
}: GetClientInvitedFriendsProps): Promise<ClientInvitedFriendProps | null> => {
  if (!clientId) {
    return null;
  }

  const clientInvitedFriendReseponse = (await axios.get(
    `/api/users/invited-friends/${clientId}`,
    {
      params: {
        limit,
        page,
        // search,
      },
    },
  )) as ClientInvitedFriendResponseProps;

  console.log(
    'our event reservations ara e , => ',
    clientInvitedFriendReseponse,
  );

  const users = extractclientInvitedFriendsHistory(
    clientInvitedFriendReseponse?.users as ClientInvitedFriend[],
  );

  return { meta: clientInvitedFriendReseponse?.meta, users };
};

type QueryFnType = typeof getClientInvitedFriends;

export type UseClietInvitedFriendsOptions = {
  config?: QueryConfig<QueryFnType>;
  query: {
    limit?: number;
    page?: number;
    search?: string;
    clientId: string;
  };
};

export const useClientInvitedFriends = ({
  config,
  query: { limit, page, search, clientId },
}: UseClietInvitedFriendsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [
      `ClientInvitedFriendsHistory/${clientId}${search}/${page}/${limit}`,
    ],
    queryFn: () =>
      getClientInvitedFriends({
        limit,
        page,
        search,
        clientId,
      }),
    ...config,
  });
};
