const Guide = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 22.4214C5.07 22.4214 0.25 17.6014 0.25 11.6714C0.25 9.31139 1 7.07139 2.42 5.19139C2.67 4.86139 3.14 4.80139 3.47 5.05139C3.8 5.30139 3.87 5.77139 3.62 6.10139C2.40196 7.70235 1.74481 9.65975 1.75 11.6714C1.75 16.7714 5.9 20.9214 11 20.9214C16.1 20.9214 20.25 16.7714 20.25 11.6714C20.25 6.57139 16.1 2.42139 11 2.42139C10.59 2.42139 10.25 2.08139 10.25 1.67139C10.25 1.26139 10.59 0.921387 11 0.921387C16.93 0.921387 21.75 5.74139 21.75 11.6714C21.75 17.6014 16.93 22.4214 11 22.4214Z"
        stroke="none"
      />
      <path
        d="M11 19.4214C6.73 19.4214 3.25 15.9414 3.25 11.6714C3.25 11.2614 3.59 10.9214 4 10.9214C4.41 10.9214 4.75 11.2614 4.75 11.6714C4.75 15.1214 7.55 17.9214 11 17.9214C14.45 17.9214 17.25 15.1214 17.25 11.6714C17.25 8.22139 14.45 5.42139 11 5.42139C10.59 5.42139 10.25 5.08139 10.25 4.67139C10.25 4.26139 10.59 3.92139 11 3.92139C15.27 3.92139 18.75 7.40139 18.75 11.6714C18.75 15.9414 15.27 19.4214 11 19.4214Z"
        stroke="none"
      />
      <path
        d="M11 16.4214C10.59 16.4214 10.25 16.0814 10.25 15.6714C10.25 15.2614 10.59 14.9214 11 14.9214C12.79 14.9214 14.25 13.4614 14.25 11.6714C14.25 9.88139 12.79 8.42139 11 8.42139C10.59 8.42139 10.25 8.08139 10.25 7.67139C10.25 7.26139 10.59 6.92139 11 6.92139C13.62 6.92139 15.75 9.05139 15.75 11.6714C15.75 14.2914 13.62 16.4214 11 16.4214Z"
        stroke="none"
      />
    </svg>
  );
};

export default Guide;
