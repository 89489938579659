import React from 'react';

const DownloadReceiptIcon = () => {
  return (
    <svg viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 15.95C3.98333 15.95 2.68767 15.425 1.613 14.375C0.537667 13.325 0 12.0417 0 10.525C0 9.22505 0.391667 8.06672 1.175 7.05005C1.95833 6.03338 2.98333 5.38338 4.25 5.10005C4.53333 3.90005 5.24167 2.75838 6.375 1.67505C7.50833 0.591715 8.71667 0.0500488 10 0.0500488C10.55 0.0500488 11.021 0.245715 11.413 0.637049C11.8043 1.02905 12 1.50005 12 2.05005V8.10005L12.9 7.22505C13.0833 7.04172 13.3127 6.95005 13.588 6.95005C13.8627 6.95005 14.1 7.05005 14.3 7.25005C14.4833 7.43338 14.575 7.66672 14.575 7.95005C14.575 8.23338 14.4833 8.46672 14.3 8.65005L11.7 11.25C11.6 11.35 11.4917 11.4207 11.375 11.462C11.2583 11.504 11.1333 11.525 11 11.525C10.8667 11.525 10.7417 11.504 10.625 11.462C10.5083 11.4207 10.4 11.35 10.3 11.25L7.7 8.65005C7.51667 8.46672 7.421 8.23738 7.413 7.96205C7.40433 7.68738 7.5 7.45005 7.7 7.25005C7.88333 7.06672 8.11233 6.97072 8.387 6.96205C8.66233 6.95405 8.9 7.04172 9.1 7.22505L10 8.10005V2.05005C8.73333 2.28338 7.75 2.89572 7.05 3.88705C6.35 4.87905 6 5.90005 6 6.95005H5.5C4.53333 6.95005 3.70833 7.29172 3.025 7.97505C2.34167 8.65838 2 9.48338 2 10.45C2 11.4167 2.34167 12.2417 3.025 12.925C3.70833 13.6084 4.53333 13.95 5.5 13.95H17.5C18.2 13.95 18.7917 13.7084 19.275 13.225C19.7583 12.7417 20 12.15 20 11.45C20 10.75 19.7583 10.1584 19.275 9.67505C18.7917 9.19172 18.2 8.95005 17.5 8.95005H16V6.95005C16 6.15005 15.8167 5.40405 15.45 4.71205C15.0833 4.02072 14.6 3.43338 14 2.95005V0.625049C15.2333 1.20838 16.2083 2.07105 16.925 3.21305C17.6417 4.35438 18 5.60005 18 6.95005C19.15 7.08338 20.1043 7.57905 20.863 8.43705C21.621 9.29572 22 10.3 22 11.45C22 12.7 21.5627 13.7627 20.688 14.638C19.8127 15.5127 18.75 15.95 17.5 15.95H5.5Z"
        fill="#19915F"
      />
    </svg>
  );
};

export default DownloadReceiptIcon;
