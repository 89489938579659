import React from 'react';

const StoreReviewsConsult = () => {
  return (
    <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.15592 1.39583C3.57853 1.39583 3.02478 1.6252 2.61649 2.03349C2.20821 2.44177 1.97884 2.99552 1.97884 3.57292V10.6771C1.97884 11.2545 2.20821 11.8082 2.61649 12.2165C3.02478 12.6248 3.57853 12.8542 4.15592 12.8542H5.64551V16.4466L10.7344 12.8542H15.8434C16.4208 12.8542 16.9746 12.6248 17.3829 12.2165C17.7911 11.8082 18.0205 11.2545 18.0205 10.6771V3.57292C18.0205 2.99552 17.7911 2.44177 17.3829 2.03349C16.9746 1.6252 16.4208 1.39583 15.8434 1.39583H4.15592ZM0.833008 3.57292C0.833008 2.69162 1.1831 1.84643 1.80627 1.22326C2.42944 0.600092 3.27463 0.25 4.15592 0.25H15.8434C16.7247 0.25 17.5699 0.600092 18.1931 1.22326C18.8162 1.84643 19.1663 2.69162 19.1663 3.57292V10.6771C19.1663 11.5584 18.8162 12.4036 18.1931 13.0267C17.5699 13.6499 16.7247 14 15.8434 14H11.0983L6.12538 17.5099C5.44247 17.9921 4.49967 17.5035 4.49967 16.6675V14H4.15592C3.27463 14 2.42944 13.6499 1.80627 13.0267C1.1831 12.4036 0.833008 11.5584 0.833008 10.6771V3.57292Z" />
    </svg>
  );
};

export default StoreReviewsConsult;
