const DeleteBinTableIcon = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d="M12.1548 3.58398L11.9167 3.58398C11.9167 2.0652 10.6855 0.833984 9.16667 0.833984H7.33333C5.81455 0.833984 4.58333 2.0652 4.58333 3.58399L3.98134 3.58398L3.66667 3.58398L3.66558 3.58398L0.916667 3.58398C0.410406 3.58398 0 3.99439 0 4.50065C0 5.00691 0.410406 5.41732 0.916667 5.41732L1.83333 5.41732L1.83333 16.4173C1.83333 17.9361 3.06455 19.1673 4.58333 19.1673H11.9167C13.4355 19.1673 14.6667 17.9361 14.6667 16.4173L14.6667 5.41732L15.5833 5.41732C16.0896 5.41732 16.5 5.00691 16.5 4.50065C16.5 3.99439 16.0896 3.58398 15.5833 3.58398L12.835 3.58398L12.8333 3.58398L12.1548 3.58398ZM12.8333 5.41732H11L10.0833 5.41732L6.41667 5.41732L5.5 5.41732L3.66667 5.41732L3.66667 7.25065L3.66667 16.4173C3.66667 16.9236 4.07707 17.334 4.58333 17.334H11.9167C12.4229 17.334 12.8333 16.9236 12.8333 16.4173L12.8333 7.25065V5.41732ZM6.41667 3.58399H7.33333H8.25L9.16667 3.58398H10.0833C10.0833 3.07772 9.67293 2.66732 9.16667 2.66732L7.33333 2.66732C6.82707 2.66732 6.41667 3.07772 6.41667 3.58399ZM6.41667 7.25065C6.92293 7.25065 7.33333 7.66106 7.33333 8.16732V14.584C7.33333 15.0902 6.92293 15.5007 6.41667 15.5007C5.91041 15.5007 5.5 15.0902 5.5 14.584V8.16732C5.5 7.66106 5.91041 7.25065 6.41667 7.25065ZM11 8.16732C11 7.66106 10.5896 7.25065 10.0833 7.25065C9.57707 7.25065 9.16667 7.66106 9.16667 8.16732V14.584C9.16667 15.0902 9.57707 15.5007 10.0833 15.5007C10.5896 15.5007 11 15.0902 11 14.584V8.16732Z"
    />
  );
};

DeleteBinTableIcon.propTypes = {};

export default DeleteBinTableIcon;
