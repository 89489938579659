const Checkout = () => {
  return (
    <svg
      width="21"
      height="26"
      viewBox="0 0 21 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.86105 10.0476L1.54019 7.72747C1.18247 7.36986 1.18243 6.78997 1.54009 6.4323C1.89775 6.07464 2.47765 6.07468 2.83526 6.4324L5.1554 8.75326H19.1077C19.2504 8.75325 19.3911 8.78659 19.5186 8.85063C19.6461 8.91466 19.7569 9.00761 19.842 9.12206C19.9272 9.23652 19.9845 9.3693 20.0093 9.50981C20.034 9.65033 20.0256 9.79468 19.9846 9.93136L17.7877 17.2544C17.7312 17.4431 17.6153 17.6085 17.4574 17.726C17.2994 17.8436 17.1077 17.9071 16.9108 17.9071H5.69182V19.7379H14.8457C15.3512 19.7379 15.761 20.1477 15.761 20.6533C15.761 21.1588 15.3512 21.5686 14.8457 21.5686H4.77643C4.53366 21.5686 4.30083 21.4722 4.12916 21.3005C3.95749 21.1289 3.86105 20.896 3.86105 20.6533V10.0476ZM5.69182 10.584V16.0763H16.2297L17.8774 10.584H5.69182ZM5.23413 25.2302C4.86996 25.2302 4.52071 25.0855 4.26321 24.828C4.00571 24.5705 3.86105 24.2213 3.86105 23.8571C3.86105 23.4929 4.00571 23.1437 4.26321 22.8862C4.52071 22.6287 4.86996 22.484 5.23413 22.484C5.59829 22.484 5.94754 22.6287 6.20504 22.8862C6.46254 23.1437 6.6072 23.4929 6.6072 23.8571C6.6072 24.2213 6.46254 24.5705 6.20504 24.828C5.94754 25.0855 5.59829 25.2302 5.23413 25.2302ZM16.2187 25.2302C15.8546 25.2302 15.5053 25.0855 15.2478 24.828C14.9903 24.5705 14.8457 24.2213 14.8457 23.8571C14.8457 23.4929 14.9903 23.1437 15.2478 22.8862C15.5053 22.6287 15.8546 22.484 16.2187 22.484C16.5829 22.484 16.9321 22.6287 17.1897 22.8862C17.4472 23.1437 17.5918 23.4929 17.5918 23.8571C17.5918 24.2213 17.4472 24.5705 17.1897 24.828C16.9321 25.0855 16.5829 25.2302 16.2187 25.2302Z"
        fill="#04103B"
      />
      <path
        d="M9.93494 3.4751L12.2024 1.20759L14.47 3.4751"
        stroke="#04103B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1956 1.2146V7.34399"
        stroke="#04103B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Checkout.propTypes = {};

export default Checkout;
