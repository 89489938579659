const EditPencil = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d="M1.03075 13.3655C1.1188 13.06 1.27737 12.782 1.49239 12.5562L11.6634 1.87871C12.9903 0.485744 15.1416 0.485744 16.4685 1.87871C17.7954 3.27167 17.7954 5.53011 16.4685 6.92307L16.3182 7.08085L18.063 8.91251C18.4235 9.29094 18.4235 9.9045 18.063 10.2829L13.4476 15.1281C13.0872 15.5065 12.5027 15.5065 12.1422 15.1281C11.7818 14.7497 11.7818 14.1361 12.1422 13.7577L16.1049 9.59772L15.0128 8.45127L6.29747 17.6006C6.12389 17.7828 5.91786 17.9264 5.69177 18.0237L5.68078 18.0352L5.67612 18.0303C5.62716 18.0508 5.57727 18.0691 5.52659 18.0852L2.38071 19.0843C1.72919 19.2912 1.02208 19.1049 0.540766 18.5996C0.0594549 18.0943 -0.117959 17.352 0.079125 16.6681L1.03075 13.3655ZM15.0128 5.71043L15.1631 5.55265C15.769 4.91655 15.769 3.88523 15.1631 3.24913C14.5572 2.61302 13.5748 2.61302 12.9688 3.24913L12.8185 3.40691L15.0128 5.71043ZM11.5131 4.77733L13.7074 7.08085L5.78214 15.4008L3.58789 13.0972L11.5131 4.77733ZM2.48839 15.0005L2.55844 14.7574L4.20077 16.4815L3.96919 16.555L1.84618 17.2292L2.48839 15.0005Z"
    />
  );
};

EditPencil.propTypes = {};

export default EditPencil;
