import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { CellProps } from 'types';

const OfferTypeCell = ({ value }: CellProps) => {
  return (
    <Text
      fontSize="14px"
      color="gray.800"
      maxW="200px"
      _firstLetter={{
        textTransform: 'capitalize',
      }}
    >
      {value && <FormattedMessage id={value} />}
    </Text>
  );
};

export default OfferTypeCell;
