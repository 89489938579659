import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractclientOrdersHistory } from '../utils/extactData';

type ClientOrderResponseProps = {
  meta: Meta;
  reservations: ClientOrder[];
};
type ClientOrderProps = {
  meta: Meta;
  reservations: ClientOrderTable[];
};

type GetClientOrdersProps = {
  limit?: number;
  page?: number;
  search?: string;
  clientId: string;
};

export const getClientOrders = async ({
  limit = 8,
  page = 1, // search = '',
  clientId,
}: GetClientOrdersProps): Promise<ClientOrderProps | null> => {
  if (!clientId) {
    return null;
  }

  console.log(`our client id => ${clientId} pag => =  ${page}  `);

  const clientOrderReseponse = (await axios.get(
    `/api/reservations/client-reservations/${clientId}`,
    {
      params: {
        limit,
        page,
        // search,
      },
    },
  )) as ClientOrderResponseProps;

  console.log('our event reservations ara e , => ', clientOrderReseponse);

  const reservations = extractclientOrdersHistory(
    clientOrderReseponse?.reservations as ClientOrder[],
  );

  return { meta: clientOrderReseponse?.meta, reservations };
};

type QueryFnType = typeof getClientOrders;

export type UseClietOrdersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: {
    limit?: number;
    page?: number;
    search?: string;
    clientId: string;
  };
};

export const useClientOrders = ({
  config,
  query: { limit, page, search, clientId },
}: UseClietOrdersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`ClientOrdersHistory/${clientId}${search}/${page}/${limit}`],
    queryFn: () =>
      getClientOrders({
        limit,
        page,
        search,
        clientId,
      }),
    ...config,
  });
};
