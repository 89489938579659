import { Box, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import RatingShower from 'components/FormShower/RatingShower';
import { FormattedDate } from 'react-intl';
import { joinProfileImage } from 'utils/images';

type ReviewListEntityProps = {
  review: Review;
};

const ReviewListEntity = ({ review }: ReviewListEntityProps) => {
  return (
    <Flex>
      <Image
        src={joinProfileImage(review?.owner?.imageUrl)}
        width="50px"
        h="50px"
        borderRadius="full"
      />
      <Stack flexGrow={1} px={4}>
        <HStack>
          <Text fontSize="md" color="gray.600" fontWeight="semibold">
            {review?.owner?.displayName}
          </Text>
          <RatingShower numberStars={review?.stars} />
        </HStack>
        <Box>
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            color="gray.600"
            fontSize="sm"
          >
            {review?.message}
          </Text>
        </Box>
        <HStack justifyContent="end">
          <Text color="gray.500" fontSize="xs">
            <FormattedDate
              value={review?.createdAt}
              year="numeric"
              month="long"
              day="numeric"
              weekday="long"
            />
          </Text>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default ReviewListEntity;
