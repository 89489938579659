export const extractSellerPartners = (
  sellers: SellerPartnerResponseListEntity[],
): SellerPartnerListEntity[] => {
  return (
    sellers?.map(({ user_id, ...seller }: SellerPartnerResponseListEntity) => {
      return {
        ...seller,
        id: seller?.storeId,
        email: seller?.contactDetails?.email ?? '__',
        town: seller?.contactDetails?.town?.name ?? '',
        zip: seller?.contactDetails?.zip ?? '',
        address: seller?.contactDetails?.address ?? '',
        subscriptionDate: seller?.subscriptions?.[0]?.createdAt ?? new Date(),
        consultData: {
          id: seller?.storeId,
          user_id,
        },
      };
    }) ?? []
  );
};

export const extractSellerProducts = (
  products: ProductResponseListEntity[],
): ProductListEntity[] => {
  return (
    products?.map((product: ProductResponseListEntity) => {
      return {
        ...product,
        consultData: {
          id: product?.id,
        },
      };
    }) ?? []
  );
};
