import React from 'react';

const NotificationBoxRingIcon = () => {
  return (
    <svg viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2695 9.64534C15.539 8.79229 15.2071 8.05305 15.2071 6.79716V6.37013C15.2071 4.73354 14.8304 3.67907 14.0115 2.62459C12.7493 0.986993 10.6244 0 8.54423 0H8.45577C6.41935 0 4.36106 0.941671 3.077 2.5128C2.21333 3.58842 1.79293 4.68822 1.79293 6.37013V6.79716C1.79293 8.05305 1.48284 8.79229 0.730488 9.64534C0.176907 10.2738 0 11.0815 0 11.9557C0 12.8309 0.287226 13.6598 0.863666 14.3336C1.61602 15.1413 2.67846 15.6569 3.76375 15.7466C5.33505 15.9258 6.90634 15.9933 8.5005 15.9933C10.0937 15.9933 11.665 15.8805 13.2372 15.7466C14.3215 15.6569 15.384 15.1413 16.1363 14.3336C16.7118 13.6598 17 12.8309 17 11.9557C17 11.0815 16.8231 10.2738 16.2695 9.64534Z"
        fill="inherit"
      />
      <path
        opacity="0.4"
        d="M10.5078 17.2285C10.0079 17.1217 6.96168 17.1217 6.46177 17.2285C6.03441 17.3272 5.57227 17.5568 5.57227 18.0604C5.59711 18.5408 5.87837 18.9648 6.26797 19.2337L6.26697 19.2347C6.77086 19.6275 7.36221 19.8773 7.98139 19.9669C8.31135 20.0122 8.64727 20.0102 8.98916 19.9669C9.60734 19.8773 10.1987 19.6275 10.7026 19.2347L10.7016 19.2337C11.0912 18.9648 11.3724 18.5408 11.3973 18.0604C11.3973 17.5568 10.9351 17.3272 10.5078 17.2285Z"
        fill="inherit"
      />
    </svg>
  );
};

export default NotificationBoxRingIcon;
