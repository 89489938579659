const PaymentHistoryIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.414 8.914C17 8.328 17 7.386 17 5.5C17 3.614 17 2.672 16.414 2.086M16.414 8.914C15.828 9.5 14.886 9.5 13 9.5H9C7.114 9.5 6.172 9.5 5.586 8.914M16.414 2.086C15.828 1.5 14.886 1.5 13 1.5H9C7.114 1.5 6.172 1.5 5.586 2.086M5.586 2.086C5 2.672 5 3.614 5 5.5C5 7.386 5 8.328 5.586 8.914M12 5.5C12 5.76522 11.8946 6.01957 11.7071 6.20711C11.5196 6.39464 11.2652 6.5 11 6.5C10.7348 6.5 10.4804 6.39464 10.2929 6.20711C10.1054 6.01957 10 5.76522 10 5.5C10 5.23478 10.1054 4.98043 10.2929 4.79289C10.4804 4.60536 10.7348 4.5 11 4.5C11.2652 4.5 11.5196 4.60536 11.7071 4.79289C11.8946 4.98043 12 5.23478 12 5.5Z"
        stroke-width="1.5"
      />
      <path
        d="M17 4.5C16.2044 4.5 15.4413 4.18393 14.8787 3.62132C14.3161 3.05871 14 2.29565 14 1.5M17 6.5C16.2044 6.5 15.4413 6.81607 14.8787 7.37868C14.3161 7.94129 14 8.70435 14 9.5M5 4.5C5.79565 4.5 6.55871 4.18393 7.12132 3.62132C7.68393 3.05871 8 2.29565 8 1.5M5 6.5C5.79565 6.5 6.55871 6.81607 7.12132 7.37868C7.68393 7.94129 8 8.70435 8 9.5M4 18.888H6.26C7.27 18.888 8.293 18.994 9.276 19.196C11.031 19.5565 12.8368 19.5965 14.606 19.314C15.474 19.174 16.326 18.959 17.098 18.587C17.794 18.25 18.647 17.777 19.22 17.246C19.792 16.716 20.388 15.849 20.81 15.171C21.174 14.589 20.998 13.876 20.424 13.443C20.1015 13.2084 19.7129 13.082 19.314 13.082C18.9151 13.082 18.5265 13.2084 18.204 13.443L16.397 14.808C15.697 15.338 14.932 15.825 14.021 15.97C13.911 15.987 13.796 16.003 13.676 16.017M13.676 16.017C13.6394 16.0212 13.6027 16.0252 13.566 16.029M13.676 16.017C13.8361 15.9737 13.9828 15.8912 14.103 15.777C14.2537 15.6466 14.3767 15.4874 14.4649 15.3087C14.553 15.13 14.6045 14.9355 14.6163 14.7366C14.628 14.5377 14.5998 14.3384 14.5333 14.1506C14.4668 13.9628 14.3633 13.7902 14.229 13.643C14.0987 13.4983 13.9469 13.3746 13.779 13.276C10.982 11.607 6.629 12.878 4 14.743M13.676 16.017C13.6399 16.0249 13.603 16.0289 13.566 16.029M13.566 16.029C12.9629 16.0896 12.3553 16.0909 11.752 16.033"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M4 14C4 13.1716 3.32843 12.5 2.5 12.5C1.67157 12.5 1 13.1716 1 14V19C1 19.8284 1.67157 20.5 2.5 20.5C3.32843 20.5 4 19.8284 4 19V14Z"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default PaymentHistoryIcon;
