import { Breadcrumb, BreadcrumbItem, Stack } from '@chakra-ui/react';
import TableComponent from 'components/table/Table';
import { Link } from 'react-router-dom';
import { UserListEntity } from '../types';

import { usersColumns } from '../variables/table';
import { useUsers } from '../api/getUsers';
import { useEffect } from 'react';
import UsersUtils from '../components/table/UsersUtils';

const ListUsers = () => {
  const { data: usersData } = useUsers({});

  useEffect(() => {
    console.log('user dat => ', usersData);
  }, [usersData]);

  return (
    <Stack py="15px">
      <Breadcrumb>
        <BreadcrumbItem color="primary.500">
          <Link to="/users">Users</Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack bg="white" borderRadius="xl" spacing={0}>
        {/* <HStack px="16px" py="16px">
          <Text fontSize="14px" color="gray.600">
            {' '}
            1 row selected{' '}
          </Text>
        </HStack> */}
        <TableComponent<UserListEntity>
          name="users"
          data={usersData?.users ?? []}
          tableColumns={usersColumns}
          pageIndex={1}
          pageCount={10}
          TableUtils={UsersUtils}
          paginated={true}
        />
      </Stack>
    </Stack>
  );
};

export default ListUsers;
