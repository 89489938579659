import {
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

//types
import { TableMenuItem } from 'types';

import DeleteBinTableIcon from 'assets/icons/table/DeleteBinTableIcon';
import TableMenuIcon from 'assets/icons/table/TableMenuIcon';
import BanIcon from 'assets/icons/table/BanIcon';

//utils
import { defaultFn } from 'utils/functions';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useBanUsers } from 'features/users/api/banUsers';
import ConsultIcon from 'assets/icons/table/ConsultIcon';
import { useActivateUsers } from 'features/users/api/activateUsers';
import ActivateIcon from 'assets/icons/table/ActivateIcon';
import { useDeleteUsers } from 'features/users/api/deleteUsers';
import PaymentHistoryIcon from 'assets/icons/table/PaymentHistoryIcon';

enum UserStatus {
  ACTIVE = 'active',
  BANNED = 'banned',
}

const OrganizerMenuCell = ({ value }: any) => {
  const intl = useIntl();
  const { mutate: deleteUsers } = useDeleteUsers();
  const { mutate: banUsers } = useBanUsers();
  const { mutate: activateUsers } = useActivateUsers();
  const navigate = useNavigate();

  const menuItems: TableMenuItem[] = [
    {
      title: intl.formatMessage({ id: 'consult' }),
      Icon: ConsultIcon,
      onClick: () => {
        navigate(`/events`);
      },
    },
    {
      title: intl.formatMessage({ id: 'paymentsHistoriy' }),
      Icon: PaymentHistoryIcon,
      onClick: () => {
        navigate(`/users/partners/organizers/${value?.id}/payments`);
      },
    },
    {
      title: intl.formatMessage({ id: 'delete' }),
      Icon: DeleteBinTableIcon,
      onClick: () => {
        deleteUsers([value?.id]);
      },
    },
    {
      title: intl.formatMessage({
        id: value?.status === UserStatus?.ACTIVE ? 'ban' : 'activate',
      }),
      Icon: value?.status === UserStatus?.ACTIVE ? BanIcon : ActivateIcon,
      onClick: () => {
        if (value?.status === UserStatus?.ACTIVE) {
          banUsers([value?.id]);
        } else {
          activateUsers([value?.id]);
        }
      },
    },
  ];

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Icon fill="primary.500" viewBox="0 0 4 18">
            <TableMenuIcon />
          </Icon>
        </PopoverTrigger>
        <PopoverContent maxW={'fit-content'}>
          <Menu isOpen={true}>
            <MenuList px="10px" py="5px" rowGap="10px">
              {menuItems?.map((item: TableMenuItem, key: any) => (
                <MenuItem
                  key={key}
                  borderRadius="10px"
                  _hover={{
                    bg: 'gray.300',
                  }}
                  columnGap="20px"
                  py="15px"
                  px="20px"
                  onClick={item?.onClick ?? defaultFn}
                >
                  <Icon viewBox="0 0 19 20" fill="black.200" stroke="black.200">
                    <item.Icon />
                  </Icon>
                  <Text
                    _firstLetter={{ textTransform: 'capitalize' }}
                    color="gray.800"
                  >
                    {item?.title}{' '}
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default OrganizerMenuCell;
