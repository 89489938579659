import { Image } from '@chakra-ui/react';
import { CellProps } from 'types';

const OfferPhotoCell = ({ value }: CellProps) => {
  return (
    <>
      <Image
        alt="product image"
        src={value}
        w="40px"
        h="40px"
        borderRadius="xl"
      />
    </>
  );
};

export default OfferPhotoCell;
