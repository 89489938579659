import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { Client, ClientResponse } from '../types';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractClients } from '../utils/extactData';

type ClientResponseProps = { meta: Meta; users: ClientResponse[] };
type ClientProps = { meta: Meta; clients: Client[] };

type GetUsersProps = {
  limit?: number;
  page?: number;
  search?: string;
};

export const getClients = async ({
  limit = 8,
  page = 1, // search = '',
}: GetUsersProps): Promise<ClientProps> => {
  const clientsResponse = (await axios.get('/api/users/admin/clients', {
    params: {
      limit,
      page,
      // search,
    },
  })) as ClientResponseProps;
  const clients = extractClients(clientsResponse?.users as ClientResponse[]);

  return { meta: clientsResponse?.meta, clients };
};

type QueryFnType = typeof getClients;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: {
    limit?: number;
    page?: number;
    search?: string;
  };
};

export const useClients = ({
  config,
  query: { limit, page, search },
}: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`clients/${search}/${page}/${limit}`],
    queryFn: () =>
      getClients({
        limit,
        page,
        search,
      }),
    ...config,
  });
};
