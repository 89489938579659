import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractOffers } from 'features/services/utils/extactData';

type PartnerResponseProps = {
  meta: Meta;
  offers: OfferResponseListEntity[];
};
type PartnerProps = { meta: Meta; offers: OfferListEntity[] };

type getPartnersQueryType = {
  page?: number;
  limit?: number;
  sellerId?: string;
};

export const getSellerOffers = async ({
  page = 1,
  limit = 5,
  sellerId,
}: getPartnersQueryType): Promise<PartnerProps | null> => {
  if (!sellerId) {
    return null;
  }
  const sellerOffersResponse = (await axios.get(
    `/api/offers/offers/store/${sellerId}`,
    {
      params: {
        page,
        limit,
      },
    },
  )) as PartnerResponseProps;
  const offers = extractOffers(
    sellerOffersResponse?.offers as OfferResponseListEntity[],
  );

  return { meta: sellerOffersResponse?.meta, offers };
};

type QueryFnType = typeof getSellerOffers;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: getPartnersQueryType;
};

export const useSellerOffers = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`partners/sellerOffer/${query?.sellerId}`],
    queryFn: () => getSellerOffers(query),
    ...config,
  });
};
