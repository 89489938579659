import CustomLayouts from 'layouts/CustomLayout/CustomLayouts';
import { Route, Routes } from 'react-router-dom';
import ListSellers from '../pages/ListSellers';
import SellerDetails from '../pages/SellerDetails';
import ListSellerProducts from '../pages/SellerProducts';
import SellerOffersList from '../pages/SellerOffersList';
import SellerReviewsList from '../pages/SellerReviewsList';

//components

export const SellersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomLayouts />}>
        <Route path="" element={<ListSellers />} />
        <Route path=":sellerId">
          <Route path="" element={<SellerDetails />} />
          <Route path="products" element={<ListSellerProducts />} />
          <Route path="offers" element={<SellerOffersList />} />
          <Route path="reviews" element={<SellerReviewsList />} />
        </Route>
      </Route>
    </Routes>
  );
};
