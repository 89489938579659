import React from 'react';

const TwitterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.394 27.132">
      <path
        id="Path_179"
        data-name="Path 179"
        d="M33.394,5.212a14.274,14.274,0,0,1-3.945,1.081,6.808,6.808,0,0,0,3.012-3.784,13.681,13.681,0,0,1-4.341,1.657A6.846,6.846,0,0,0,16.277,8.848a7.049,7.049,0,0,0,.159,1.561A19.377,19.377,0,0,1,2.325,3.248a6.856,6.856,0,0,0,2.1,9.15,6.762,6.762,0,0,1-3.093-.843v.075a6.877,6.877,0,0,0,5.485,6.727,6.833,6.833,0,0,1-1.795.225,6.052,6.052,0,0,1-1.3-.117,6.91,6.91,0,0,0,6.4,4.769,13.754,13.754,0,0,1-8.488,2.92A12.824,12.824,0,0,1,0,26.06a19.272,19.272,0,0,0,10.5,3.072c12.6,0,19.485-10.435,19.485-19.481,0-.3-.01-.595-.025-.885a13.658,13.658,0,0,0,3.431-3.554Z"
        transform="translate(0 -2)"
        fill="#03a9f4"
      />
    </svg>
  );
};

export default TwitterIcon;
