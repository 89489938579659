import {
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

//types
import { TableMenuItem } from 'types';

//api

// icons
import DeleteBinTableIcon from 'assets/icons/table/DeleteBinTableIcon';
import TableMenuIcon from 'assets/icons/table/TableMenuIcon';
import BanIcon from 'assets/icons/table/BanIcon';

//utils
import { defaultFn } from 'utils/functions';
import ConsultIcon from 'assets/icons/table/ConsultIcon';
import { useDeleteUsers } from 'features/users/api/deleteUsers';
import { useNavigate } from 'react-router-dom';
import { useBanUsers } from 'features/users/api/banUsers';

const ClientMenuCell = ({ value }: any) => {
  const navigate = useNavigate();
  const { mutate: deleteClient } = useDeleteUsers();
  const { mutate: banClient } = useBanUsers();

  const menuItems: TableMenuItem[] = [
    {
      title: 'consult',
      Icon: ConsultIcon,
      onClick: () => {
        navigate(`/users/clients/${value?.id}/history/events`);
      },
    },
    {
      title: 'delete',
      Icon: DeleteBinTableIcon,
      onClick: () => {
        deleteClient([value?.id]);
      },
    },
    {
      title: 'ban',
      Icon: BanIcon,
      onClick: () => {
        banClient([value?.id]);
      },
    },
  ];

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Icon fill="primary.500" viewBox="0 0 4 18">
            <TableMenuIcon />
          </Icon>
        </PopoverTrigger>
        <PopoverContent maxW={'fit-content'}>
          <Menu isOpen={true}>
            <MenuList px="10px" py="5px" rowGap="10px">
              {menuItems?.map((item: TableMenuItem, key: any) => (
                <MenuItem
                  key={key}
                  borderRadius="10px"
                  _hover={{
                    bg: 'gray.300',
                  }}
                  columnGap="20px"
                  py="15px"
                  px="20px"
                  onClick={item?.onClick ?? defaultFn}
                >
                  <Icon viewBox="0 0 19 20" fill="black.200">
                    <item.Icon />
                  </Icon>
                  <Text
                    _firstLetter={{ textTransform: 'capitalize' }}
                    color="gray.800"
                  >
                    {' '}
                    {item?.title}{' '}
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ClientMenuCell;
