import {
  Center,
  // Circle,
  Flex,
  Image,
} from '@chakra-ui/react';
import If from 'common/If';
import FieldWrapper, {
  FieldWrapperPassThroughProps,
} from 'components/form/FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  images: Media[];
};

const MultipleImagesShower = ({ images, label }: InputFieldProps) => {
  return (
    <If condition={images?.length > 0}>
      <FieldWrapper label={label}>
        <Flex
          py={5}
          columnGap={{ base: 2, lg: 10 }}
          rowGap={{ base: 5, lg: 30 }}
          flexWrap="wrap"
        >
          {images?.map((image: any, key: any) => {
            return (
              <Center
                key={key}
                w={{ base: 120, lg: 150 }}
                h={{ base: 120, lg: 130 }}
                cursor="pointer"
                borderRadius="3xl"
                position={'relative'}
              >
                <Image
                  src={image?.url ?? null}
                  h="100%"
                  w="100%"
                  objectFit={'cover'}
                  borderRadius={'xl'}
                />
              </Center>
            );
          })}
        </Flex>
      </FieldWrapper>
    </If>
  );
};

export default MultipleImagesShower;
