const Sort = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="none"
        d="M3.67085 4.83752C3.44305 5.06533 3.44305 5.43467 3.67085 5.66248C3.89866 5.89028 4.26801 5.89028 4.49581 5.66248L7 3.15829L9.50419 5.66248C9.73199 5.89028 10.1013 5.89029 10.3291 5.66248C10.557 5.43467 10.557 5.06533 10.3291 4.83752L7.41248 1.92085C7.30308 1.81146 7.15471 1.75 7 1.75C6.84529 1.75 6.69692 1.81146 6.58752 1.92085L3.67085 4.83752Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="none"
        d="M10.3291 9.16232C10.557 8.93451 10.557 8.56516 10.3291 8.33736C10.1013 8.10955 9.73199 8.10955 9.50419 8.33736L7 10.8415L4.49581 8.33736C4.26801 8.10955 3.89866 8.10955 3.67085 8.33736C3.44305 8.56516 3.44305 8.93451 3.67085 9.16232L6.58752 12.079C6.81533 12.3068 7.18467 12.3068 7.41248 12.079L10.3291 9.16232Z"
      />
    </>
  );
};

export default Sort;
