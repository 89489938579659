import React, { useEffect, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { BsStarFill, BsStar, BsStarHalf } from 'react-icons/bs';
import If from 'common/If';

type RatingProps = {
  numberStars?: number;
};

const RatingShower = ({ numberStars = 5 }: RatingProps) => {
  const [stars, setStars] = useState<number[]>([]);

  useEffect(() => {
    const snapStars = [];
    let snapValue = numberStars;
    for (let i = 0; i < snapValue; i++) {
      if (snapValue <= 0) {
        snapStars.push(0);
      }

      if (snapValue >= 1) {
        snapStars.push(1);
      }
      if (snapValue > 0 && snapValue < 1) {
        snapStars.push(0.5);
      }

      snapValue = snapValue - 1;
    }

    setStars(snapStars);
  }, [numberStars]);

  return (
    <HStack dir="ltr">
      {stars.map((number: number, key: any) => {
        return (
          <Box color="yellow.500">
            <If condition={number === 0}>
              <BsStar fontSize={'20px'} key={key} />
            </If>
            <If condition={number === 1}>
              <BsStarFill fontSize={'20px'} key={key} />
            </If>
            <If condition={number === 0.5}>
              <BsStarHalf fontSize={'20px'} key={key} />
            </If>
          </Box>
        );
      })}
    </HStack>
  );
};

export default RatingShower;
