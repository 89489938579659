import { useLocation } from 'react-router-dom';

const usePage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams?.get('page');

  return {
    page,
  };
};

export default usePage;
