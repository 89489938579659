import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractSellerPartners } from '../utils/extactData';

type PartnerResponseProps = {
  meta: Meta;
  users: SellerPartnerResponseListEntity[];
};
type PartnerProps = { meta: Meta; sellers: SellerPartnerListEntity[] };

type getPartnersQueryType = {
  page?: number;
  limit?: number;
};

export const getSellers = async ({
  page = 1,
  limit = 5,
}: getPartnersQueryType): Promise<PartnerProps> => {
  const sellersResponse = (await axios.get('/api/users/admin/parteners', {
    params: {
      filterBy: 'seller',
      page,
      limit,
    },
  })) as PartnerResponseProps;
  const sellers = extractSellerPartners(
    sellersResponse?.users as SellerPartnerResponseListEntity[],
  );

  return { meta: sellersResponse?.meta, sellers };
};

type QueryFnType = typeof getSellers;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query?: getPartnersQueryType;
};

export const useSellers = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['partners/seller'],
    queryFn: () => getSellers(query ?? {}),
    ...config,
  });
};
