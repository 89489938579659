const Logout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6573 21.27H13.5273C9.08732 21.27 6.94732 19.52 6.57732 15.6C6.53732 15.19 6.83732 14.82 7.25732 14.78C7.65732 14.74 8.03732 15.05 8.07732 15.46C8.36732 18.6 9.84732 19.77 13.5373 19.77H13.6673C17.7373 19.77 19.1773 18.33 19.1773 14.26V7.73998C19.1773 3.66998 17.7373 2.22998 13.6673 2.22998H13.5373C9.82732 2.22998 8.34732 3.41998 8.07732 6.61998C8.02732 7.02998 7.67732 7.33998 7.25732 7.29998C7.15877 7.29228 7.0627 7.2652 6.97463 7.22028C6.88657 7.17537 6.80823 7.11351 6.74413 7.03826C6.68002 6.96301 6.6314 6.87583 6.60106 6.78175C6.57072 6.68766 6.55926 6.58851 6.56732 6.48998C6.90732 2.50998 9.05732 0.72998 13.5273 0.72998H13.6573C18.5673 0.72998 20.6673 2.82998 20.6673 7.73998V14.26C20.6673 19.17 18.5673 21.27 13.6573 21.27Z"
        stroke="none"
      />
      <path
        d="M13.4174 11.75H2.03735C1.62735 11.75 1.28735 11.41 1.28735 11C1.28735 10.59 1.62735 10.25 2.03735 10.25H13.4174C13.8274 10.25 14.1674 10.59 14.1674 11C14.1674 11.41 13.8274 11.75 13.4174 11.75Z"
        stroke="none"
      />
      <path
        d="M4.26738 15.0998C4.07738 15.0998 3.88738 15.0298 3.73738 14.8798L0.387379 11.5298C0.247899 11.3887 0.169678 11.1983 0.169678 10.9998C0.169678 10.8014 0.247899 10.611 0.387379 10.4698L3.73738 7.11984C4.02738 6.82984 4.50738 6.82984 4.79738 7.11984C5.08738 7.40984 5.08738 7.88984 4.79738 8.17984L1.97738 10.9998L4.79738 13.8198C5.08738 14.1098 5.08738 14.5898 4.79738 14.8798C4.65738 15.0298 4.45738 15.0998 4.26738 15.0998Z"
        stroke="none"
      />
    </svg>
  );
};

Logout.propTypes = {};

export default Logout;
