import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { useQuery } from '@tanstack/react-query';

type GetProfitChartQuery = {
  endsIn: string;
  beginsIn: string;
  value: number;
  unit: 'day' | 'week' | 'moment';
};

export const getAdminProfitChart = async (
  query: GetProfitChartQuery,
): Promise<ProfitChart> => {
  return await axios.get(`/api/statistic/get-admin-profit-chart`, {
    params: {
      ...query,
    },
  });
};

type QueryFnType = typeof getAdminProfitChart;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query: GetProfitChartQuery;
};

export const useAdminProfitChart = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`admin.profit-chart`],
    queryFn: () => getAdminProfitChart(query),
    ...config,
  });
};
