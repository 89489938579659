const Blog = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 22.4214C5.07 22.4214 0.25 17.6014 0.25 11.6714C0.25 5.74139 5.07 0.921387 11 0.921387C16.93 0.921387 21.75 5.74139 21.75 11.6714C21.75 17.6014 16.93 22.4214 11 22.4214ZM11 2.42139C5.9 2.42139 1.75 6.57139 1.75 11.6714C1.75 16.7714 5.9 20.9214 11 20.9214C16.1 20.9214 20.25 16.7714 20.25 11.6714C20.25 6.57139 16.1 2.42139 11 2.42139Z"
        stroke="none"
      />
      <path
        d="M11.0007 17.0606C10.8233 17.0606 10.6543 17.0353 10.5106 16.9846C8.69381 16.3677 5.81226 14.1537 5.81226 10.8581C5.81226 9.10046 7.23191 7.67236 8.98112 7.67236C9.74164 7.67236 10.443 7.93432 11.0092 8.40754C11.5754 7.93432 12.2852 7.67236 13.0288 7.67236C14.778 7.67236 16.1977 9.10046 16.1977 10.8581C16.1977 14.1453 13.3161 16.3593 11.4909 16.9846C11.3472 17.0353 11.1697 17.0606 11.0007 17.0606V17.0606ZM8.97267 8.94836C7.92483 8.94836 7.07135 9.81029 7.07135 10.8666C7.07135 13.8157 9.9191 15.4551 10.9162 15.7931C10.95 15.8015 11.0345 15.8015 11.0683 15.7931C12.0655 15.4551 14.9132 13.8157 14.9132 10.8666C14.9132 9.81029 14.0597 8.94836 13.0119 8.94836C12.4204 8.94836 11.8542 9.23567 11.4993 9.71734C11.2627 10.0384 10.7219 10.0384 10.4853 9.71734C10.3132 9.4773 10.086 9.28215 9.82278 9.14832C9.55953 9.01449 9.26797 8.94591 8.97267 8.94836V8.94836Z"
        stroke="none"
      />
    </svg>
  );
};

export default Blog;
