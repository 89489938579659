import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { Meta } from 'features/global';
import { useQuery } from '@tanstack/react-query';
import { extractEventOrganizers } from '../utils/extactData';

type PartnerResponseProps = {
  meta: Meta;
  users: EventOrganizer[];
};
type PartnerProps = {
  meta: Meta;
  eventOrganizers: EventOrganizerTable[];
};

type getPartnersQueryType = {
  page?: number;
  limit?: number;
};

export const getEventOrganizers = async ({
  page = 1,
  limit = 5,
}: getPartnersQueryType): Promise<PartnerProps> => {
  const eventOrganizersResponse = (await axios.get(
    '/api/users/admin/parteners',
    {
      params: {
        filterBy: 'organizer',
        page,
        limit,
      },
    },
  )) as PartnerResponseProps;
  const eventOrganizers = extractEventOrganizers(
    eventOrganizersResponse?.users as EventOrganizer[],
  );

  return { meta: eventOrganizersResponse?.meta, eventOrganizers };
};

type QueryFnType = typeof getEventOrganizers;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
  query?: getPartnersQueryType;
};

export const useEventOrganizers = ({ config, query }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['partners/eventOrganizer'],
    queryFn: () => getEventOrganizers(query ?? {}),
    ...config,
  });
};
