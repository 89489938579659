import { HStack, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import usePage from 'hooks/usePage';
import useListRefetch from 'hooks/useListRefetch';
import { useSellerReviews } from '../api/getSellerReviews';
import ReviewListEntity from '../components/ReviewListEntity';
import Pagination from 'components/pagination/Pagination';

const SellerReviewsList = () => {
  const { page } = usePage();
  const { sellerId } = useParams();

  const { data: reviews, refetch: refetchSellers } = useSellerReviews({
    query: {
      page: Number(page ?? '1'),
      limit: 8,
      sellerId,
    },
  });

  useListRefetch({
    refetch: refetchSellers,
    page: page,
    currentPage: reviews?.meta?.currentPage,
  });

  const breadItems = [
    {
      name: <FormattedMessage id="users" />,
    },
    {
      name: <FormattedMessage id="partners" />,
    },
    {
      name: <FormattedMessage id="vendors" />,
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breadItems} />
      </HStack>

      <Stack spacing={8} py={10}>
        {reviews?.reviews?.map((review: Review) => {
          return <ReviewListEntity key={review?.id} review={review} />;
        })}
      </Stack>
      <HStack py="10px" justifyContent="end">
        <Pagination
          page={Number(page ?? '1')}
          pages={reviews?.meta?.totalPages ?? 1}
        />
      </HStack>
    </Stack>
  );
};

export default SellerReviewsList;
