import { User, UserListEntity } from '../types';

export const extractUsers = (usersResponse: User[]): UserListEntity[] => {
  return (
    usersResponse?.map((userResponse: User) => ({
      id: userResponse?.user_id,
      ...userResponse,
    })) ?? []
  );
};
