import { Flex, HStack, Stack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import { useSeller } from '../api/getStore';
import { useParams } from 'react-router-dom';
import InputShower from 'components/FormShower/InputShower';
import SocialMediaUrls from 'components/FormShower/SocialMediaUrls';
import MultipleImagesShower from 'components/FormShower/MultipleImagesShower';
import { joinStoreImage } from 'utils/images';

const SellerDetails = () => {
  const { sellerId } = useParams();

  const { data: store } = useSeller({ sellerId });

  const breadItems = [
    {
      name: <FormattedMessage id="users" />,
    },
    {
      name: <FormattedMessage id="partners" />,
    },
    {
      name: <FormattedMessage id="vendors" />,
      link: '/users/partners/sellers',
    },
    {
      name: store?.name ?? '',
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breadItems} />
      </HStack>

      <Flex py={8} flexWrap="wrap" columnGap="8" rowGap={4}>
        <InputShower
          // label={intl.formatMessage({ id: 'storeName' })}
          label="storeName"
          defaultValue={store?.name}
          wrapperStyles={{
            w: '400px',
          }}
          inputStyle={{
            variant: 'primary',
            fontSize: 'xs',
            size: 'lg',
            fontWeight: 'normal',
          }}
        />
        <InputShower
          label="storeCategory"
          defaultValue={store?.type?.name}
          wrapperStyles={{
            w: '400px',
          }}
          inputStyle={{
            variant: 'primary',
            fontSize: 'xs',
            size: 'lg',
            fontWeight: 'normal',
          }}
        />
        <InputShower
          label="mapsLink"
          defaultValue={`https://www.google.com/maps/@${store?.latitude},${store?.longitude},12z`}
          wrapperStyles={{
            w: '400px',
          }}
          inputStyle={{
            variant: 'primary',
            fontSize: 'xs',
            size: 'lg',
            fontWeight: 'normal',
          }}
        />

        <SocialMediaUrls
          value={store?.socialMediaUrls ?? []}
          label="socialMedia"
        />
        <MultipleImagesShower
          images={
            store?.medias?.map((media: Media) => {
              return {
                ...media,
                url: joinStoreImage(media?.url),
              };
            }) ?? []
          }
          label="pictures"
        />
      </Flex>
    </Stack>
  );
};

export default SellerDetails;
