import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { axios } from 'lib/axios';
import { useQuery } from '@tanstack/react-query';

type BoxStats = {
  totalTicketsSold: number;
  totalProfit: {
    event: number;
    subs: number;
  };
  numberOfProviders: number;
  numberOfClients: number;
};

export const getBoxStats = async (): Promise<BoxStats> => {
  return await axios.get(`/api/statistic/get-stat`);
};

type QueryFnType = typeof getBoxStats;

export type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useBoxStats = ({ config }: UseUsersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [`boxStats`],
    queryFn: () => getBoxStats(),
    ...config,
  });
};
