import CustomLayouts from 'layouts/CustomLayout/CustomLayouts';
import { Route, Routes } from 'react-router-dom';
import ListEventOrganizers from '../pages/ListEventOrganizers';
import ListOrganizerPayments from '../pages/ListOrganizerPayments';

//components

export const OrganizersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomLayouts />}>
        <Route path="" element={<ListEventOrganizers />} />
        <Route path=":organizerId">
          <Route path="payments" element={<ListOrganizerPayments />} />
        </Route>
      </Route>
    </Routes>
  );
};
