const TableMenuIcon = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-8.74228e-08 16C-3.91405e-08 17.1046 0.89543 18 2 18C3.10457 18 4 17.1046 4 16C4 14.8954 3.10457 14 2 14C0.89543 14 -1.35705e-07 14.8954 -8.74228e-08 16ZM-3.93402e-07 9C-3.4512e-07 10.1046 0.89543 11 2 11C3.10457 11 4 10.1046 4 9C4 7.89543 3.10457 7 2 7C0.89543 7 -4.41685e-07 7.89543 -3.93402e-07 9ZM2 4C0.89543 4 -6.511e-07 3.10457 -6.99382e-07 2C-7.47664e-07 0.895432 0.89543 -3.91405e-08 2 -8.74228e-08C3.10457 -1.35705e-07 4 0.895431 4 2C4 3.10457 3.10457 4 2 4Z"
    />
  );
};

export default TableMenuIcon;
