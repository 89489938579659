import { HStack, Icon, IconButton } from '@chakra-ui/react';
import BanIcon from 'assets/icons/table/BanIcon';
import DeleteBinTableIcon from 'assets/icons/table/DeleteBinTableIcon';
import { useBanUsers } from 'features/users/api/banUsers';
import { useDeleteUsers } from 'features/users/api/deleteUsers';
import { useIntl } from 'react-intl';

type UsersUtilsProps = {
  ids?: string[];
};

const UsersUtils = ({ ids = [] }: UsersUtilsProps) => {
  const { mutate: deleteUsers } = useDeleteUsers();
  const { mutate: banUsers } = useBanUsers();
  const intl = useIntl();

  const deleteStyles =
    ids?.length > 0
      ? {
          fill: 'danger.500',
        }
      : {};

  const banStyles =
    ids?.length > 0
      ? {
          fill: 'orange.500',
        }
      : {};
  const handleDeleteEvents = () => {
    alert(JSON.stringify(ids));
    deleteUsers(ids);
  };

  const handleBanUsers = () => {
    banUsers(ids);
  };

  return (
    <HStack spacing="5px">
      <IconButton
        variant="grayTableUtils"
        aria-label={intl?.formatMessage({ id: 'deleteUserAction' })}
        border="none"
        isDisabled={false}
        {...deleteStyles}
        onClick={handleDeleteEvents}
        icon={
          <Icon fontSize="25px">
            <DeleteBinTableIcon />
          </Icon>
        }
      />

      <IconButton
        variant="grayTableUtils"
        aria-label={intl?.formatMessage({ id: 'banUserAction' })}
        border="none"
        isDisabled={false}
        {...banStyles}
        onClick={handleBanUsers}
        icon={
          <Icon fontSize="25px">
            <BanIcon />
          </Icon>
        }
      />
    </HStack>
  );
};

export default UsersUtils;
