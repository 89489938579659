import { useEffect } from 'react';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { clientInvitedFriendsColumns } from '../variables/table';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import TableComponent from 'components/table/Table';
import ChevronDown from 'assets/icons/chevrons/ChevronDown';
import TypeHistorySelect from '../components/TypeHistorySelect';
import { FormattedMessage } from 'react-intl';
import usePage from 'hooks/usePage';
import { useClientInvitedFriends } from '../api/getClientInvitedFriends';

const ClientInvitedFriendsHistory = () => {
  const { page } = usePage();
  const { clientId } = useParams();

  const { data: ordersData, refetch: refetchInvitedFriends } =
    useClientInvitedFriends({
      query: {
        limit: 8,
        page: Number(page ?? '1'),
        clientId: clientId as string,
      },
    });

  useEffect(() => {
    if (Number(page) !== ordersData?.meta?.currentPage) {
      refetchInvitedFriends();
    }
  }, [page, clientId]);

  const breadItems = [
    {
      name: <FormattedMessage id="users" />,
    },
    {
      name: <FormattedMessage id="clients" />,
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breadItems} />
        <HStack>
          <Text
            color="primary.500"
            _firstLetter={{ textTransform: 'capitalize' }}
            fontWeight="semibold"
          >
            <FormattedMessage id="view" />
          </Text>

          <Menu>
            <MenuButton
              //  color="primary.500"
              minW="150px"
              borderRadius="31px"
              fontSize="14px"
              py="5px"
              bg="white"
              fontWeight="500"
              h="31px"
              px="20px"
              justifyContent="space-between"
              as={Button}
              variant="whiteFill"
              rightIcon={
                <Icon fill="none" viewBox="0 0 12 8" stroke="gray.500">
                  {' '}
                  <ChevronDown />{' '}
                </Icon>
              }
            >
              <Text
                _firstLetter={{ textTransform: 'capitalize' }}
                textAlign="start"
                fontWeight="semibold"
                fontSize="sm"
              >
                <FormattedMessage id={'invitedFriendsHistory'} />
              </Text>
            </MenuButton>

            <TypeHistorySelect />
          </Menu>
        </HStack>
      </HStack>
      <TableComponent<ClientInvitedFriendTable>
        name="clientUsedInvitedFriendsHistories"
        // name="users"
        // selectRow={navigateInvitedFriendDetails}
        hideSelection={true}
        paginated={true}
        data={ordersData?.users ?? []}
        tableColumns={clientInvitedFriendsColumns}
        pageIndex={Number(page ?? '1')}
        pageCount={ordersData?.meta?.totalPages ?? 1}
        // TableUtils={UsersUtils}
        //
        // searching={setSearch}
        // detailsIcon={true}
      />
    </Stack>
  );
};

export default ClientInvitedFriendsHistory;
