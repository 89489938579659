import { Stack, Text, Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import LineCharts from 'components/charts/LineCharts';
import { useAdminProfitChart } from '../api/getAdminProfitChart';
import moment from 'moment';
import If from 'common/If';

const ProfitChart = () => {
  const endsIn = moment();
  const beginsIn = endsIn.clone().add(-24, 'day');

  const { data: profitChart } = useAdminProfitChart({
    query: {
      beginsIn: beginsIn.format('YYYY-MM-DD'),
      endsIn: endsIn.format('YYYY-MM-DD'),
      value: 1,
      unit: 'day',
    },
  });
  // useEffect(() => {
  //   const snapData = [...data];

  //   snapData[3] += 100;
  //   setData(snapData);
  // }, [data]);
  return (
    <Stack spacing="15px">
      <Text color="primary.500" textTransform="capitalize" fontWeight="medium">
        <FormattedMessage id="profitschart" />
      </Text>

      <Box bg="white" borderRadius={'xl'}>
        <If condition={profitChart}>
          <LineCharts
            categories={
              profitChart?.intervals?.map((interval: string) => {
                return moment(interval).format('DD');
              }) ?? []
            }
            data={[
              {
                name: 'Admin profit',
                //   data  : [
                //     1500,
                // 1000
                // 2000,
                // 6,
                // 8,
                // 10,
                // 12,
                // 14,
                // 16,
                // 18,
                // 20,
                // 24,

                //   ]
                data: profitChart?.profits?.map((profit) => {
                  return profit.SubsAmount + profit.eventAmount;
                }),
              },
            ]}
          />
        </If>
      </Box>
    </Stack>
  );
};

export default ProfitChart;
