const ChevronDown = () => {
  return (
    <path
      d="M10.0833 1.95898L5.99996 6.04232L1.91663 1.95898"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

ChevronDown.propTypes = {};

export default ChevronDown;
