import { HStack, Stack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import CustomBreadcrumb from 'components/CustomBreadcrumb/CustomBreadcrumb';
import { useClients } from '../api/getClients';
import TableComponent from 'components/table/Table';
import { Client } from '../types';
import { clientsColumns } from '../variables/table';
import UsersUtils from 'features/users/components/table/UsersUtils';
import useListRefetch from 'hooks/useListRefetch';
import usePage from 'hooks/usePage';

const ListClients = () => {
  const { page } = usePage();
  const { data: clients, refetch: refetchClient } = useClients({
    query: {
      limit: 8,
      page: Number(page ?? '1'),
    },
  });

  useListRefetch({
    refetch: refetchClient,
    currentPage: clients?.meta?.currentPage,
    page: page,
  });

  const breadItems = [
    {
      name: <FormattedMessage id="users" />,
    },
    {
      name: <FormattedMessage id="clients" />,
    },
  ];

  return (
    <Stack py="15px" spacing="10px">
      <HStack justifyContent="space-between">
        <CustomBreadcrumb items={breadItems} />
      </HStack>
      <TableComponent<Client>
        name="clients"
        // name="users"
        // selectRow={navigateOrderDetails}
        paginated={true}
        data={clients?.clients ?? []}
        tableColumns={clientsColumns}
        pageIndex={Number(page ?? '1')}
        pageCount={clients?.meta?.totalPages ?? 1}
        TableUtils={UsersUtils}
        //
        // searching={setSearch}
        // detailsIcon={true}
      />
    </Stack>
  );
};

ListClients.propTypes = {};

export default ListClients;
