const ImageUploadIcon = () => {
  return (
    <>
      <path
        d="M27.6091 48.7459H2.846C2.4268 48.7459 2.02476 48.5793 1.72834 48.2829C1.43191 47.9865 1.26538 47.5845 1.26538 47.1652V2.90777C1.26538 2.48856 1.43191 2.08653 1.72834 1.7901C2.02476 1.49368 2.4268 1.32715 2.846 1.32715H47.1035C47.5227 1.32715 47.9247 1.49368 48.2212 1.7901C48.5176 2.08653 48.6841 2.48856 48.6841 2.90777V27.6709"
        stroke="black"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.26562 35.5742L19.7062 27.6711L34.1953 34.257M35.5125 43.4773H43.4156M51.3187 43.4773H43.4156M43.4156 43.4773V35.5742M43.4156 43.4773V51.3804M35.5125 19.768C34.1151 19.768 32.775 19.2129 31.7869 18.2248C30.7988 17.2367 30.2437 15.8966 30.2437 14.4992C30.2437 13.1019 30.7988 11.7617 31.7869 10.7736C32.775 9.78557 34.1151 9.23047 35.5125 9.23047C36.9098 9.23047 38.25 9.78557 39.238 10.7736C40.2261 11.7617 40.7812 13.1019 40.7812 14.4992C40.7812 15.8966 40.2261 17.2367 39.238 18.2248C38.25 19.2129 36.9098 19.768 35.5125 19.768V19.768Z"
        stroke="black"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export default ImageUploadIcon;
