const Services = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M1.90421 2.46916C3.06427 1.30889 4.62021 0.630251 6.2598 0.569415C7.8994 0.508579 9.50135 1.07005 10.7442 2.14116C11.986 1.07183 13.5858 0.510995 15.2234 0.570926C16.861 0.630857 18.4156 1.30714 19.5759 2.46437C20.7361 3.62161 21.4165 5.17439 21.4807 6.81185C21.5449 8.44932 20.9883 10.0506 19.9222 11.2952L12.1572 19.0852C11.8005 19.442 11.322 19.6509 10.8177 19.6698C10.3135 19.6887 9.8207 19.5163 9.43821 19.1872L9.32821 19.0862L1.56421 11.2952C0.498696 10.0517 -0.0582136 8.45187 0.00482177 6.81554C0.0678572 5.17922 0.746192 3.62698 1.90421 2.46916ZM3.31821 3.88316C2.50045 4.70115 2.02906 5.80261 2.00187 6.95893C1.97469 8.11526 2.39379 9.23765 3.17221 10.0932L3.31821 10.2472L10.7432 17.6722L16.0462 12.3682L12.5112 8.83316L11.4512 9.89316C11.1727 10.1718 10.842 10.3929 10.478 10.5438C10.114 10.6946 9.72391 10.7723 9.32991 10.7724C8.5342 10.7726 7.771 10.4567 7.20821 9.89416C6.64542 9.33164 6.32914 8.56859 6.32895 7.77287C6.32876 6.97715 6.64468 6.21395 7.20721 5.65116L9.30921 3.54816C8.47268 2.88054 7.42562 2.53285 6.35589 2.56749C5.28617 2.60213 4.2638 3.01682 3.47221 3.73716L3.31821 3.88316ZM11.8042 6.71116C11.9917 6.52369 12.246 6.41838 12.5112 6.41838C12.7764 6.41838 13.0307 6.52369 13.2182 6.71116L17.4602 10.9532L18.1682 10.2472C18.9997 9.4163 19.473 8.29308 19.487 7.11773C19.5011 5.94237 19.0546 4.80818 18.2432 3.95772C17.4318 3.10726 16.3199 2.60803 15.1452 2.56682C13.9704 2.5256 12.8262 2.94566 11.9572 3.73716L11.8042 3.88316L8.62221 7.06516C8.44888 7.23838 8.34518 7.46921 8.3308 7.71383C8.31642 7.95845 8.39236 8.19984 8.54421 8.39216L8.62221 8.47916C8.79542 8.65249 9.02625 8.75619 9.27087 8.77057C9.51549 8.78495 9.75688 8.70901 9.94921 8.55716L10.0362 8.47916L11.8042 6.71116Z"
      />
    </svg>
  );
};

export default Services;
