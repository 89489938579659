const Event = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M13.7 21.7502H8.30001C3.36001 21.7502 1.26001 19.6402 1.26001 14.7102V10.2202C1.26001 9.81021 1.60001 9.47021 2.01001 9.47021C2.42001 9.47021 2.76001 9.81021 2.76001 10.2202V14.7102C2.76001 18.8002 4.21001 20.2502 8.30001 20.2502H13.69C17.78 20.2502 19.23 18.8002 19.23 14.7102V10.2202C19.23 9.81021 19.57 9.47021 19.98 9.47021C20.39 9.47021 20.73 9.81021 20.73 10.2202V14.7102C20.74 19.6402 18.63 21.7502 13.7 21.7502Z"
      />
      <path
        stroke="none"
        d="M10.999 11.75C9.89902 11.75 8.89903 11.32 8.18903 10.53C7.47903 9.74 7.14902 8.71 7.25902 7.61L7.92902 0.93C7.96903 0.55 8.28903 0.25 8.67903 0.25H13.349C13.739 0.25 14.059 0.54 14.099 0.93L14.769 7.61C14.879 8.71 14.549 9.74 13.839 10.53C13.099 11.32 12.099 11.75 10.999 11.75ZM9.34903 1.75L8.74902 7.76C8.67902 8.43 8.87902 9.06 9.29902 9.52C10.149 10.46 11.849 10.46 12.699 9.52C13.119 9.05 13.319 8.42 13.249 7.76L12.649 1.75H9.34903Z"
      />
      <path
        stroke="none"
        d="M17.31 11.75C15.28 11.75 13.47 10.11 13.26 8.09L12.56 1.08C12.54 0.87 12.61 0.66 12.75 0.5C12.89 0.34 13.09 0.25 13.31 0.25H16.36C19.3 0.25 20.67 1.48 21.08 4.5L21.36 7.28C21.48 8.46 21.12 9.58 20.35 10.43C19.58 11.28 18.5 11.75 17.31 11.75ZM14.14 1.75L14.76 7.94C14.89 9.19 16.05 10.25 17.31 10.25C18.07 10.25 18.75 9.96 19.24 9.43C19.72 8.9 19.94 8.19 19.87 7.43L19.59 4.68C19.28 2.42 18.55 1.75 16.36 1.75H14.14Z"
      />
      <path
        stroke="none"
        d="M4.63904 11.75C3.44904 11.75 2.36904 11.28 1.59904 10.43C0.829045 9.58 0.469045 8.46 0.589045 7.28L0.859045 4.53C1.27904 1.48 2.64904 0.25 5.58905 0.25H8.63904C8.84904 0.25 9.04905 0.34 9.19905 0.5C9.34904 0.66 9.40905 0.87 9.38904 1.08L8.68904 8.09C8.47904 10.11 6.66904 11.75 4.63904 11.75ZM5.58905 1.75C3.39904 1.75 2.66904 2.41 2.34904 4.7L2.07904 7.43C1.99904 8.19 2.22904 8.9 2.70904 9.43C3.18904 9.96 3.86904 10.25 4.63904 10.25C5.89904 10.25 7.06905 9.19 7.18904 7.94L7.80904 1.75H5.58905Z"
      />
      <path
        d="M13.5 21.75H8.5C8.09 21.75 7.75 21.41 7.75 21V18.5C7.75 16.4 8.9 15.25 11 15.25C13.1 15.25 14.25 16.4 14.25 18.5V21C14.25 21.41 13.91 21.75 13.5 21.75ZM9.25 20.25H12.75V18.5C12.75 17.24 12.26 16.75 11 16.75C9.74 16.75 9.25 17.24 9.25 18.5V20.25Z"
        stroke="none"
      />
    </svg>
  );
};

export default Event;
