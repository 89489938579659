import { Center, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import NotificationBoxRingIcon from 'assets/icons/notifications/NotificationBoxRingIcon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface NotificationBoxProps {
  seen?: boolean;
  notification: NotificationAdmin;
}

const NotificationBox = ({
  seen = false,
  notification,
}: NotificationBoxProps) => {
  const meta: {
    userId: string;
    userName: string;
  } = JSON.parse(notification?.notification?.data);

  const title =
    notification?.notification?.dataType === 'joined' ? 'USERJOIN' : '';

  const label =
    notification?.notification?.dataType === 'joined' ? meta?.userName : '';

  const href =
    notification?.notification?.dataType === `joined`
      ? `/users/clients/${meta?.userId}/history/events`
      : '';

  return (
    <Link to={href}>
      <HStack
        opacity={seen ? '.7' : '1'}
        bg="white"
        borderRadius="xl"
        boxShadow="md"
        p={'10px'}
        gap="15px"
        cursor="pointer"
      >
        <Center bg="badge.green.100" borderRadius="full" p="10px">
          <Icon width="20px" height="20px" fill="primary.500">
            <NotificationBoxRingIcon />
          </Icon>
        </Center>
        <Stack spacing="1px">
          <Text color="gray.800" fontSize="md" fontWeight="semibold">
            {title && <FormattedMessage id={title}></FormattedMessage>}
          </Text>
          <Text color="gray.800" fontSize="sm">
            {label}
          </Text>
        </Stack>
      </HStack>
    </Link>
  );
};

export default NotificationBox;
