import { useEffect } from 'react';

type UseListRefetchProps = {
  refetch: any;
  currentPage?: number;
  page: any;
};
const useListRefetch = ({
  refetch,
  currentPage = 1,
  page,
}: UseListRefetchProps) => {
  useEffect(() => {
    if (Number(page) !== currentPage) {
      refetch();
    }
  }, [page]);
};

export default useListRefetch;
